import { takeEvery, all, takeLatest, takeLeading } from 'redux-saga/effects'
import * as types from 'Actions/types'

import * as activity from './activity'
import * as address from './address'
import * as app from './app'
import * as apply from './apply'
import * as auth from './auth'
import * as automation from './automation'
import * as cache from './cache'
import * as campaign from './campaign'
import * as candidate from './candidate'
import * as certificate from './certificate'
import * as company from './company'
import * as dashboard from './dashboard'
import * as deletion from './deletion'
import * as email from './email'
import * as entity from './entity'
import * as event from './event'
import * as exports from './export'
import * as funnel from './funnel'
import * as job from './job'
import * as lookup from './lookup'
import * as notification from './notification'
import * as pdf from './pdf'
import * as project from './project'
import * as quick from './quick'
import * as relation from './relation'
import * as search from './search'
import * as share from './share'
import * as shell from './shell'
import * as table from './table'
import * as task from './task'
import * as template from './template'
import * as user from './user'

const watchActivity = [
  takeEvery(types.ACTIVITY_GET_ACTIVITIES, activity.getActivitiesSaga),
]

const watchAddress = [
  takeEvery(types.ADDRESS_GET_BY_LINE, address.getByLineSaga),
  takeEvery(types.ADDRESS_GET_BY_ZIP, address.getByZipSaga),
]

const watchApp = [
  takeEvery(types.APP_CHANGE_LANGUAGE, app.changeLanguageSaga),
  takeEvery(types.APP_GOTO_URL, app.gotoUrlSaga),
  takeLeading(types.APP_SEND_ERROR, app.sendErrorSaga),
  takeEvery(types.APP_START_VERSION_CHECK, app.startVersionCheckSaga),
]

const watchApply = [
  takeEvery(types.APPLY_GET_DATA, apply.getDataSaga),
  takeEvery(types.APPLY_POST_DATA, apply.postDataSaga),
]

const watchAuth = [
  takeEvery(types.AUTH_ACTIVATE, auth.activateSaga),
  takeEvery(types.AUTH_ACTIVATE_INFO, auth.activateInfoSaga),
  takeEvery(types.AUTH_CANCEL_CHANGE_EMAIL, auth.cancelChangeEmailSaga),
  takeEvery(types.AUTH_CHANGE_COMPANY, auth.changeCompanySaga),
  takeEvery(types.AUTH_CHANGE_EMAIL, auth.changeEmailSaga),
  takeEvery(types.AUTH_CHANGE_PASSWORD, auth.changePasswordSaga),
  takeEvery(types.AUTH_CHECK_EMAIL, auth.checkEmailSaga),
  takeEvery(types.AUTH_CHECK_PASSWORD, auth.checkPasswordSaga),
  takeEvery(types.AUTH_CONNECT_NYLAS, auth.connectNylasSaga),
  takeEvery(types.AUTH_GET_ACCOUNT_STATUS, auth.getAccountStatusSaga),
  takeEvery(types.AUTH_GET_ACCOUNTS, auth.getAccountsSaga),
  takeEvery(types.AUTH_GET_PARTNER, auth.getPartnerSaga),
  takeEvery(types.AUTH_GET_QRCODE, auth.getQrCodeSaga),
  takeEvery(types.AUTH_GET_SESSIONS, auth.getSessionsSaga),
  takeEvery(types.AUTH_GET_SUBSCRIPTION, auth.getSubscriptionSaga),
  takeEvery(types.AUTH_INITIALIZE_SYSTEM, auth.initializeSystem),
  takeEvery(types.AUTH_PASSWORDLINK, auth.sendPasswordLinkSaga),
  takeEvery(types.AUTH_PREVIEW_MODE, auth.previewModeSaga),
  takeEvery(types.AUTH_PUT_SUBSCRIPTION, auth.putSubscriptionSaga),
  takeEvery(types.AUTH_REGISTER_NYLAS, auth.registerNylasSaga),
  takeEvery(types.AUTH_REMOVE_ACCOUNT, auth.removeAccountSaga),
  takeEvery(types.AUTH_REMOVE_EMAIL2, auth.removeEmail2Saga),
  takeEvery(types.AUTH_RESET_PASSWORD, auth.resetPasswordSaga),
  takeEvery(types.AUTH_SESSION_INIT, auth.initSessionSaga),
  takeEvery(types.AUTH_SEND_RECOVERY_CODE, auth.sendRecoveryCodeSaga),
  takeEvery(types.AUTH_SEND_VERIFICATION_CODE, auth.sendVerificationCodeSaga),
  takeEvery(types.AUTH_SET_SSO, auth.setSsoSaga),
  takeEvery(types.AUTH_SHOW_SSO, auth.showSsoSaga),
  takeEvery(types.AUTH_SIGNIN, auth.signinSaga),
  takeEvery(types.AUTH_SIGNUP, auth.signupSaga),
  takeEvery(types.AUTH_SIGNOUT, auth.signoutSaga),
  takeEvery(types.AUTH_SSO_SIGNIN, auth.ssoSigninSaga),
  takeEvery(types.AUTH_SSO_SIGNIN_FAILED, auth.ssoSigninFailedSaga),
  takeEvery(types.AUTH_SSO_SIGNIN_SUCCEED, auth.ssoSigninSucceedSaga),
  takeEvery(types.AUTH_STOP_SUBSCRIPTION, auth.stopSubscriptionSaga),
  takeEvery(types.AUTH_SOURCES_LOAD, auth.sourcesLoadSaga),
  takeEvery(types.AUTH_SOURCES_REMOVE, auth.sourcesRemoveSaga),
  takeEvery(types.AUTH_SOURCES_RENAME, auth.sourcesRenameSaga),
  takeEvery(types.AUTH_TAGS_LOAD, auth.tagsLoadSaga),
  takeEvery(types.AUTH_TAGS_REMOVE, auth.tagsRemoveSaga),
  takeEvery(types.AUTH_TAGS_RENAME, auth.tagsRenameSaga),
  takeEvery(types.AUTH_TWOFACTOR_ENABLE, auth.twoFactorEnableSaga),
  takeEvery(types.AUTH_UPDATE_ACCOUNT, auth.updateAccountSaga),
  takeEvery(types.AUTH_VALIDATE_EMAIL, auth.validateEmailSaga),
  takeEvery(types.AUTH_VALIDATE_TOKEN, auth.validateTokenSaga),
]

const watchAutomation = [
  takeEvery(types.AUTOMATION_GET_LIST, automation.getListSaga),
  takeEvery(types.AUTOMATION_GET_LOG, automation.getLogSaga),
  takeEvery(types.AUTOMATION_START, automation.startSaga),
  takeEvery(types.AUTOMATION_STOP, automation.stopSaga),
  takeEvery(types.AUTOMATION_UPDATE_SETTINGS, automation.updateSettingsSaga),
]

const watchCache = [
  takeLeading(types.CACHE_LOAD_COMPANIES, cache.loadCompaniesSaga),
  takeLeading(types.CACHE_LOAD_FILTERS, cache.loadFiltersSaga),
  takeLeading(types.CACHE_LOAD_LOOKUPS, cache.loadLookupsSaga),
  takeLeading(types.CACHE_LOAD_TAGS, cache.loadTagsSaga),
  takeLeading(types.CACHE_LOAD_TEMPLATES, cache.loadTemplatesSaga),
  takeLeading(types.CACHE_LOAD_USERS, cache.loadUsersSaga),
  takeLeading(types.CACHE_LOAD_VIEWERS, cache.loadViewersSaga),
]

const watchCampaign = [
  takeLeading(types.CAMPAIGN_ADD_CHANNEL, campaign.addChannelSaga),
  takeLeading(
    [types.CAMPAIGN_GET_CHANNELS, types.CAMPAIGN_SET_FILTER],
    campaign.getChannelsSaga
  ),
  takeLeading(types.CAMPAIGN_LOAD_LISTS, campaign.getListsSaga),
  takeLeading(types.CAMPAIGN_REFRESH_CHANNELS, campaign.refreshChannelsSaga),
  takeLeading(types.CAMPAIGN_REMOVE_CHANNEL, campaign.removeChannelSaga),
  takeLeading(types.CAMPAIGN_SET_FAVORITE, campaign.setFavoriteSaga),
  takeLeading(types.CAMPAIGN_UNSET_FAVORITE, campaign.unsetFavoriteSaga),
]

const watchCandidate = [
  takeEvery(types.CANDIDATE_ADD_EDUCATION, candidate.addEducationSaga),
  takeEvery(types.CANDIDATE_ADD_EMPLOYMENT, candidate.addEmploymentSaga),
  takeEvery(types.CANDIDATE_ADD_SHORTLIST, candidate.addShortlistSaga),
  takeEvery(types.CANDIDATE_ADD_SKILL, candidate.addSkillSaga),
  takeEvery(types.CANDIDATE_ADD_TALENTPOOL, candidate.addTalentpoolSaga),
  takeEvery(types.CANDIDATE_CHECK_DUPLICATE, candidate.checkDuplicate),
  takeEvery(types.CANDIDATE_CREATE_SHARE, candidate.createShareSaga),
  takeEvery(
    types.CANDIDATE_DELETE_ALTERNATIVE,
    candidate.deleteAlternativeSaga
  ),
  takeEvery(types.CANDIDATE_DELETE_COVER, candidate.deleteCoverSaga),
  takeEvery(types.CANDIDATE_DELETE_EDUCATION, candidate.deleteEducationSaga),
  takeEvery(types.CANDIDATE_DELETE_EMPLOYMENT, candidate.deleteEmploymentSaga),
  takeEvery(types.CANDIDATE_DELETE_PHOTO, candidate.deletePhotoSaga),
  takeEvery(types.CANDIDATE_DELETE_RESUME, candidate.deleteResumeSaga),
  takeEvery(types.CANDIDATE_DELETE_SKILL, candidate.deleteSkillSaga),
  takeEvery(types.CANDIDATE_EXTEND_GDPR, candidate.extendGdprSaga),
  takeEvery(types.CANDIDATE_GET_COMPANY_SHARES, candidate.getCompanySharesSaga),
  takeEvery(types.CANDIDATE_GET_CREDITS, candidate.getCreditsSaga),
  takeEvery(types.CANDIDATE_GET_GDPR_INFO, candidate.getGdprInfoSaga),
  takeEvery(types.CANDIDATE_GET_JOBS_INIT, candidate.getJobsSaga),
  takeEvery(types.CANDIDATE_GET_JSON, candidate.getJsonSaga),
  takeEvery(types.CANDIDATE_GET_PROFILE_SHARES, candidate.getProfileSharesSaga),
  takeEvery(types.CANDIDATE_GET_PROJECTS, candidate.getProjectsSaga),
  takeEvery(types.CANDIDATE_GET_SHARES, candidate.getSharesSaga),
  takeEvery(types.CANDIDATE_GET_SHARE_DEFAULTS, candidate.getShareDefaultsSaga),
  takeEvery(types.CANDIDATE_GET_SHARE_VIEWS, candidate.getShareViewsSaga),
  takeEvery(types.CANDIDATE_GET_SHORTLIST_INIT, candidate.getShortlistSaga),
  takeLatest(types.CANDIDATE_GET_VIEWERS, candidate.getViewersSaga),
  takeEvery(types.CANDIDATE_LOAD_DUPLICATES, candidate.loadDuplicatesSaga),
  takeLeading(types.CANDIDATE_LOAD_SOURCES, candidate.loadSourcesSaga),
  takeEvery(types.CANDIDATE_MERGE, candidate.mergeSaga),
  takeEvery(types.CANDIDATE_MERGE_GET_INFO, candidate.mergeGetInfoSaga),
  takeEvery(
    types.CANDIDATE_PARSE_EXISTING_RESUME,
    candidate.parseExistingResumeSaga
  ),
  takeEvery(types.CANDIDATE_PARSE_RESUME, candidate.parseResumeSaga),
  takeEvery(
    types.CANDIDATE_PROCESS_REQUEST_GDPR,
    candidate.processRequestGdprSaga
  ),
  takeEvery(types.CANDIDATE_REMOVE_SHARE, candidate.removeShareSaga),
  takeEvery(types.CANDIDATE_REMOVE_SHORTLIST, candidate.removeShortlistSaga),
  takeEvery(types.CANDIDATE_REMOVE_TALENTPOOL, candidate.removeTalentpoolSaga),
  takeEvery(types.CANDIDATE_SELECT_JOBS, candidate.selectJobsSaga),
  takeEvery(types.CANDIDATE_SEND_GDPR_CONSENT, candidate.sendGdprConsentSaga),
  takeEvery(types.CANDIDATE_SEND_GDPR_REQUEST, candidate.sendGdprRequestSaga),
  takeEvery(types.CANDIDATE_SEND_SHARE, candidate.sendShareSaga),
  takeEvery(types.CANDIDATE_SHOW_SHARES, candidate.showSharesSaga),
  takeEvery(types.CANDIDATE_TALENTPOOL_ADD, candidate.addToTalentpoolSaga),
  takeEvery(
    types.CANDIDATE_TALENTPOOL_REMOVE,
    candidate.removeFromTalentpoolSaga
  ),
  takeEvery(types.CANDIDATE_UPDATE_RATING, candidate.updateRatingSaga),
  takeEvery(types.CANDIDATE_UPDATE_SHARE, candidate.updateShareSaga),
  takeEvery(
    types.CANDIDATE_UPLOAD_ALTERNATIVE,
    candidate.uploadAlternativeSaga
  ),
  takeEvery(types.CANDIDATE_UPDATE_EDUCATION, candidate.updateEducationSaga),
  takeEvery(types.CANDIDATE_UPDATE_EMPLOYMENT, candidate.updateEmploymentSaga),
  takeEvery(types.CANDIDATE_UPDATE_SKILL, candidate.updateSkillSaga),
  takeEvery(types.CANDIDATE_UPGRADE_RESUME, candidate.upgradeResumeSaga),
  takeEvery(types.CANDIDATE_UPLOAD_COVER, candidate.uploadCoverSaga),
  takeEvery(types.CANDIDATE_UPLOAD_PHOTO, candidate.uploadPhotoSaga),
  takeEvery(types.CANDIDATE_UPLOAD_RESUME, candidate.uploadResumeSaga),
]

const watchCertificate = [
  takeEvery(
    types.CERTIFICATE_ADD_CERTIFICATES,
    certificate.addCertificatesSaga
  ),
]

const watchCompany = [
  takeEvery(types.COMPANY_ACTIVATE, company.activateSaga),
  takeEvery(types.COMPANY_CLEAR_CACHE, company.clearCacheSaga),
  takeEvery(types.COMPANY_CONNECT_ROBIN, company.connectRobinSaga),
  takeEvery(types.COMPANY_CREATE, company.createSaga),
  takeEvery(types.COMPANY_DEACTIVATE, company.deactivateSaga),
  takeEvery(types.COMPANY_DELETE_MY_PHOTO, company.deleteMyPhotoSaga),
  takeEvery(types.COMPANY_EDIT, company.editSaga),
  takeEvery(types.COMPANY_GET_HIRING, company.getHiringSaga),
  takeEvery(types.COMPANY_GET_MY, company.getMySaga),
  takeEvery(types.COMPANY_GET_PHASES, company.getPhasesSaga),
  takeEvery(types.COMPANY_GET_WEBHOOKS, company.getWebhooksSaga),
  takeEvery(types.COMPANY_PUT_MY, company.putMySaga),
  takeEvery(types.COMPANY_REMOVE_WEBHOOK, company.removeWebhookSaga),
  takeEvery(types.COMPANY_RENEW_TOKEN, company.renewTokenSaga),
  takeEvery(types.COMPANY_TEST_WEBHOOK, company.testWebhookSaga),
  takeEvery(types.COMPANY_UPDATE_PHASE, company.updatePhaseSaga),
  takeEvery(types.COMPANY_UPDATE_SCOPE, company.updateScopeSaga),
  takeEvery(types.COMPANY_UPDATE_TENANT_NAME, company.updateTenantNameSaga),
  takeEvery(types.COMPANY_UPDATE_WEBHOOK, company.updateWebhookSaga),
  takeEvery(types.COMPANY_UPLOAD_MY_PHOTO, company.uploadMyPhotoSaga),
]

const watchDashboard = [
  takeLatest(types.DASHBOARD_GET_DEFINITION, dashboard.getDefinitionSaga),
  takeEvery(types.DASHBOARD_GOTO_TAB, dashboard.gotoTabSaga),
  takeEvery(types.DASHBOARD_SET_TAB_NAME, dashboard.setTabNameSaga),
  takeEvery(types.DASHBOARD_WIDGET_ADD, dashboard.widgetAddSaga),
  takeEvery(types.DASHBOARD_WIDGET_MOVE, dashboard.widgetMoveSaga),
  takeEvery(types.DASHBOARD_WIDGET_REMOVE, dashboard.widgetRemoveSaga),
  takeEvery(types.DASHBOARD_WIDGET_GET, dashboard.widgetGetSaga),
  takeEvery(types.DASHBOARD_WIDGET_SET_SETTING, dashboard.widgetSetSettingSaga),
  takeEvery(
    [
      types.DASHBOARD_WIDGET_LEGEND_VISIBLE,
      types.DASHBOARD_WIDGET_LEGEND_INVISIBLE,
    ],
    dashboard.widgetSetInvibleSaga
  ),
  takeEvery(types.DASHBOARD_WIDGET_SIZE, dashboard.widgetSizeSaga),
]

const watchDeletion = [
  takeEvery(types.DELETION_GET_NUMBER, deletion.getNumberSaga),
  takeEvery(types.DELETION_RESTORE_ITEMS, deletion.restoreItemsSaga),
]

const watchEmail = [
  takeEvery(types.EMAIL_ADD_ATTACHMENT, email.createAttachmentSaga),
  takeEvery(types.EMAIL_ADD_RECIPIENTS, email.addRecipients),
  takeEvery(types.EMAIL_CONNECT, email.connectSaga),
  takeLatest(types.EMAIL_CREATE_CANDIDATE, email.createCandidateSaga),
  takeEvery(types.EMAIL_DELETE_DRAFT, email.deleteDraftSaga),
  takeEvery(types.EMAIL_GET_TEMPLATE, email.getTemplateSaga),
  takeEvery(types.EMAIL_SAVE_JOB, email.saveJobSaga),
  takeEvery(types.EMAIL_SEND, email.sendSaga),
  takeEvery(types.EMAIL_SEND_BULK, email.sendBulkSaga),
  takeEvery(types.EMAIL_SHOW_BULKEMAIL, email.showBulkEmailSaga),
  takeEvery(types.EMAIL_SHOW_NEWEMAIL, email.showNewEmailSaga),
  takeEvery(types.EMAIL_TRANSLATE, email.translateSaga),
  takeEvery(types.EMAIL_VIEW_GET, email.viewGetSaga),
]

const watchEntity = [
  takeEvery(types.ENTITY_ADD_NOTE, entity.addNoteSaga),
  takeEvery(types.ENTITY_ADD_TAG_INIT, entity.addTagSaga),
  takeEvery(types.ENTITY_ANONIMYZE_ITEM, entity.anonymizeItemSaga),
  takeEvery(types.ENTITY_CHANGE_COMPANYID, entity.changeCompanyIdSaga),
  takeEvery(types.ENTITY_CREATE, entity.createSaga),
  takeEvery(types.ENTITY_DELETE_CERTIFICATE, entity.deleteCertificateSaga),
  takeEvery(types.ENTITY_DELETE_ITEM, entity.deleteItemSaga),
  takeEvery(types.ENTITY_DELETE_NOTE, entity.deleteNoteSaga),
  takeEvery(types.ENTITY_DELETE_UNSAVED_NOTE, entity.deleteUnsavedNoteSaga),
  takeEvery(types.ENTITY_EXPAND_NOTE, entity.expandNoteSaga),
  takeEvery(types.ENTITY_GET, entity.getSaga),
  takeEvery(types.ENTITY_GET_ACTIVITIES, entity.getActivitiesSaga),
  takeEvery(types.ENTITY_GET_ATTACHMENTS_INIT, entity.getAttachmentsSaga),
  takeEvery(types.ENTITY_GET_CERTIFICATES, entity.getCertificatesSaga),
  takeEvery(types.ENTITY_GET_CERTIFICATE_URL, entity.getCertificateUrlSaga),
  takeEvery(types.ENTITY_GET_EMAILS, entity.getEmailsSaga),
  takeLatest(types.ENTITY_GET_EVENTS, entity.getEventsSaga),
  takeLatest(types.ENTITY_GET_FILES, entity.getFilesSaga),
  takeEvery(types.ENTITY_GET_INSIGHTS_URL, entity.getInsightsUrlSaga),
  takeEvery(types.ENTITY_GET_LAST_NOTE_INIT, entity.getLastNoteSaga),
  takeEvery(types.ENTITY_GET_NOTES_INIT, entity.getNotesSaga),
  takeEvery(types.ENTITY_GET_NOTES_USERS, entity.getNotesUsersSaga),
  takeLatest(types.ENTITY_GET_TASKS, entity.getTasksSaga),
  takeEvery(types.ENTITY_GET_UNSAVED_NOTE, entity.getUnsavedNoteSaga),
  takeEvery(
    [types.ENTITY_PUT, types.JOB_UPDATE_PUBLISH, types.RELATION_UPDATE_CLAIMED],
    entity.updateSaga
  ),
  takeEvery(types.ENTITY_MARK, entity.markSaga),
  takeEvery(types.ENTITY_NOTE_GET_VIEWERS, entity.noteGetViewersSaga),
  takeEvery(types.ENTITY_REMOVE_TAG_INIT, entity.removeTagSaga),
  takeEvery(types.ENTITY_REFRESH, entity.refreshSaga),
  takeEvery(types.ENTITY_RESTORE, entity.restoreSaga),
  takeEvery(types.ENTITY_SAVE_UNSAVED_NOTE, entity.saveUnsavedNoteSaga),
  takeEvery(types.ENTITY_SET_NOTE_SECURED, entity.setNoteSecuredSaga),
  takeEvery(types.ENTITY_UNMARK, entity.unMarkSaga),
  takeEvery(types.ENTITY_UPDATE_CERTIFICATE, entity.updateCertificateSaga),
  takeEvery(types.ENTITY_UPDATE_NOTE, entity.updateNoteSaga),
  takeEvery(types.ENTITY_UPDATE_NOTE_TYPE, entity.updateNoteTypeSaga),
  takeEvery(
    types.ENTITY_UPLOAD_NOTE_ATTACHMENT,
    entity.uploadNoteAttachmentSaga
  ),
]

const watchEvent = [
  takeEvery(types.EVENT_CREATE, event.createSaga),
  takeEvery(types.EVENT_DOWNLOAD_ICS, event.downloadIcs),
  takeEvery(types.EVENT_GET_ITEM, event.getItemSaga),
  takeEvery(types.EVENT_GET_ITEM_RANGE, event.getItemRangeSaga),
  takeEvery(types.EVENT_GET_LOCATIONS, event.getLocationsSaga),
  takeLatest(types.EVENT_GET_RANGE, event.getRangeSaga),
  takeEvery(types.EVENT_GET_SHARED_AGENDAS, event.getSharedAgendasSaga),
  takeEvery(types.EVENT_REMOVE, event.removeSaga),
  takeEvery(types.EVENT_REMOVE_RECURRENCY, event.removeRecurrencySaga),
  takeEvery(types.EVENT_SET_NEW_ITEM, event.setNewItemSaga),
  takeEvery(types.EVENT_SHARED_AGENDAS_CHANGED, event.sharedAgendasChangedSaga),
  takeEvery(types.EVENT_SAVE_NEW, event.saveNewSaga),
  takeEvery(types.EVENT_UPDATE, event.updateSaga),
]

const watchExports = [
  takeEvery(types.EXPORT_LOAD_EXPORTS, exports.loadExportsSaga),
]

const watchFunnel = [
  takeEvery(types.FUNNEL_ADD_CUSTOMER, funnel.addCustomerSaga),
  takeLatest([types.FUNNEL_GET_FUNNEL], funnel.getFunnelSaga),
  takeEvery(types.FUNNEL_MOVE_CUSTOMER, funnel.moveCustomerSaga),
  takeEvery(types.FUNNEL_REMOVE_CUSTOMER, funnel.removeCustomerSaga),
  takeEvery(types.FUNNEL_TIMELINE_LOAD, funnel.timelineLoadSaga),
]

const watchJob = [
  takeEvery(types.JOB_ADD_LANGUAGE, job.addLanguageSaga),
  takeEvery(types.JOB_ASK_REVIEW, job.askReviewSaga),
  takeEvery(types.JOB_ASK_SEND_EMAIL, job.askSendEmailSaga),
  takeEvery(types.JOB_ASSIGN_PLACEMENT_TO, job.assignPlacementToSaga),
  takeEvery(types.JOB_CHANGE_BRANCH, job.changeBranchSaga),
  takeEvery(types.JOB_COPY, job.copySaga),
  takeEvery(types.JOB_DELETE_ALTERNATIVE, job.deleteAlternativeSaga),
  takeEvery(types.JOB_DELETE_LANGUAGE, job.deleteLanguageSaga),
  takeEvery(types.JOB_DELETE_VACANCY, job.deleteVacancySaga),
  takeLatest(types.JOB_GET_CANDIDATE_VIEWERS, job.getCandidateViewersSaga),
  takeLatest(types.JOB_GET_LANGUAGE, job.getLanguageSaga),
  takeLatest(types.JOB_GET_PLACEMENTS, job.getPlacementsSaga),
  takeEvery(types.JOB_GET_REVIEW_USERS, job.getReviewUsersSaga),
  takeLeading(types.JOB_LOAD_SOURCES, job.loadSourcesSaga),
  takeEvery(types.JOB_MOVE_CANDIDATE, job.moveCandidateSaga),
  takeEvery(types.JOB_MOVE_CANDIDATES, job.moveCandidatesSaga),
  takeEvery(types.JOB_NETIVE_GET, job.getNetiveSaga),
  takeEvery(types.JOB_NETIVE_GET_JSON, job.getNetiveJsonSaga),
  takeEvery(types.JOB_NETIVE_UPDATE_PROPOSAL, job.netiveUpdateProposalSaga),
  takeEvery(types.JOB_REMOVE_CANDIDATE_INIT, job.removeCandidateSaga),
  takeEvery(types.JOB_REMOVE_IMAGE, job.removeImageSaga),
  takeEvery(types.JOB_SELECT_CANDIDATES, job.selectCandidatesSaga),
  takeEvery(types.JOB_SET_PLACEMENT_ONHOLD, job.setPlacementOnholdSaga),
  takeEvery(types.JOB_SET_PLACEMENT_ORIGIN, job.setPlacementOriginSaga),
  takeEvery(types.JOB_SET_PLACEMENT_STATUS, job.setPlacementStatusSaga),
  takeEvery(types.JOB_TIMELINE_LOAD, job.timelineLoadSaga),
  takeEvery(types.JOB_UPDATE_IMAGE, job.updateImageSaga),
  takeEvery(types.JOB_UPDATE_LANGUAGE, job.updateLanguageSaga),
  takeEvery(types.JOB_UPLOAD_ALTERNATIVE, job.uploadAlternativeSaga),
  takeEvery(types.JOB_UPLOAD_DESCRIPTION, job.uploadDescriptionSaga),
  takeEvery(types.JOB_UPLOAD_VACANCY, job.uploadVacancySaga),
]

const watchLookup = [
  takeEvery(types.LOOKUP_ADD_ITEM, lookup.addItemSaga),
  takeEvery(types.LOOKUP_DELETE_ITEM, lookup.deleteItemSaga),
  takeEvery(types.LOOKUP_GET_LOOKUP, lookup.getLookupSaga),
  takeEvery(types.LOOKUP_IMPORT_EXCEL, lookup.importExcelSaga),
  takeEvery(types.LOOKUP_LOAD_COUNTRIES, lookup.loadCountriesSaga),
  takeEvery(types.LOOKUP_LOAD_LANGUAGES, lookup.loadLanguagesSaga),
  takeEvery(types.LOOKUP_MOVE_ITEM, lookup.moveItemSaga),
  takeEvery(types.LOOKUP_RESTORE_ALL, lookup.restoreAllSaga),
  takeEvery(types.LOOKUP_RESTORE_ITEM, lookup.restoreItemSaga),
  takeEvery(types.LOOKUP_SORT_LIST, lookup.sortListSaga),
  takeEvery(types.LOOKUP_UPDATE_ITEM, lookup.updateItemSaga),
]

const watchNotification = [
  takeLatest(types.NOTIFICATION_GET_NEW, notification.getNewSaga),
  takeEvery(
    types.NOTIFICATION_GET_SUBSCRIPTIONS,
    notification.getSubscriptionsSaga
  ),
  takeLatest(types.NOTIFICATION_LOAD_ALL, notification.loadAllSaga),
  takeLatest(
    types.NOTIFICATION_RECEIVE_MESSAGE,
    notification.receiveMessageSaga
  ),
  takeLatest(types.NOTIFICATION_REMOVE_READ, notification.removeReadSaga),
  takeEvery(types.NOTIFICATION_SET_READ, notification.setReadSaga),
  takeEvery(types.NOTIFICATION_SET_READ_ALL, notification.setReadAllSaga),
  takeEvery(
    types.NOTIFICATION_UPDATE_SUBSCRIPTION,
    notification.updateSubscriptionSaga
  ),
]

const watchPdf = [
  takeEvery(types.PDF_CREATE, pdf.createSaga),
  takeEvery(types.PDF_GENERATE, pdf.generateSaga),
]

const watchProject = [
  takeEvery(types.PROJECT_ADD_TIMELINE, project.addTimelineSaga),
  takeEvery(types.PROJECT_CHECK_TIMELINE, project.checkTimelineSaga),
  takeEvery(types.PROJECT_DELETE_TIMELINE, project.deleteTimelineSaga),
  takeEvery(types.PROJECT_EDIT_TIMELINE, project.editTimelineSaga),
  takeEvery(types.PROJECT_GET_CANDIDATES, project.getCandidatesSaga),
  takeEvery(types.PROJECT_GET_PLANNING, project.getPlanningSaga),
  takeEvery(types.PROJECT_GET_TIMELINE, project.getTimelineSaga),
  takeLatest(types.PROJECT_GET_TIMELINES, project.getTimelinesSaga),
  takeEvery(types.PROJECT_GOTO_PROJECT, project.gotoProjectSaga),
  takeEvery(types.PROJECT_JOB_MOVE_DOWN, project.jobMoveDownSaga),
  takeEvery(types.PROJECT_JOB_MOVE_UP, project.jobMoveUpSaga),
  takeLeading(types.PROJECT_LOAD_SOURCES, project.loadSourcesSaga),
  takeEvery(types.PROJECT_REMOVE_JOB, project.removeJobSaga),
]

const watchQuick = [
  takeLatest(types.QUICK_GET_ITEM, quick.getItemSaga),
  takeLatest(types.QUICK_GET_LIST, quick.getListSaga),
  takeLatest(types.QUICK_PIN_ITEM, quick.pinItemSaga),
  takeLatest(types.QUICK_UNPIN_ITEM, quick.unpinItemSaga),
]

const watchRelation = [
  takeEvery(types.RELATION_ADD_SHORTLIST, relation.addShortlistSaga),
  takeEvery(types.RELATION_DELETE_PHOTO, relation.deletePhotoSaga),
  takeEvery(types.RELATION_GET_CANDIDATES_INIT, relation.getCandidatesSaga),
  takeEvery(types.RELATION_GET_JOBS_INIT, relation.getJobsSaga),
  takeEvery(types.RELATION_GET_PRIMARY_CONTACT, relation.getPrimaryContactSaga),
  takeEvery(types.RELATION_GET_SHORTLIST_INIT, relation.getShortlistSaga),
  takeLeading(types.RELATION_LOAD_SOURCES, relation.loadSourcesSaga),
  takeEvery(types.RELATION_REMOVE_SHORTLIST, relation.removeShortlistSaga),
  takeEvery(types.RELATION_SEARCH_CLAIMS, relation.searchClaimsSaga),
  takeEvery(types.RELATION_SEARCH_COC, relation.searchCocSaga),
  takeEvery(types.RELATION_SELECT_CONTACTS, relation.selectContactsSaga),
  takeEvery(types.RELATION_UPLOAD_PHOTO, relation.uploadPhotoSaga),
]

const watchSearch = [takeEvery(types.SEARCH_SEARCH_TEXT, search.searchTextSaga)]

const watchShare = [
  takeEvery(types.SHARE_ACCEPT_CANDIDATE, share.acceptCandidateSaga),
  takeEvery(types.SHARE_GET_CANDIDATE, share.getCandidateSaga),
  takeEvery(types.SHARE_GET_CANDIDATES, share.getCandidatesSaga),
  takeEvery(types.SHARE_GET_NOTES, share.getNotesSaga),
  takeEvery(types.SHARE_GET_PHASES, share.getPhasesSaga),
  takeEvery(types.SHARE_GET_REJECT_REASONS, share.getRejectReasonsSaga),
  takeEvery(types.SHARE_REJECT_CANDIDATE, share.rejectCandidateSaga),
  takeEvery(types.SHARE_UPDATE_EVALUATION, share.updateEvaluationSaga),
  takeEvery(types.SHARE_UPDATE_NOTE, share.updateNoteSaga),
  takeEvery(types.SHARE_UPDATE_VIEWER, share.updateViewerSaga),
]

const watchShell = [
  takeEvery(types.SHELL_CONFIRM, shell.confirmSaga),
  takeEvery(types.SHELL_MESSAGE, shell.messageSaga),
]

const watchTable = [
  takeEvery(types.TABLE_ADD_TAG_FILTER, table.addTagFilterSaga),
  takeEvery(types.TABLE_ADD_TALENTPOOL_FILTER, table.addTalentpoolFilterSaga),
  takeEvery(types.TABLE_ADD_TAGS, table.addTagsSaga),
  takeEvery(types.TABLE_DELETE_CURRENT_FILTER, table.deleteCurrentFilterSaga),
  takeEvery(types.TABLE_DELETE_ITEMS, table.deleteItemsSaga),
  takeLatest(
    [
      types.TABLE_ADD_FILTER_ITEM,
      types.TABLE_DELETE_FILTER,
      types.TABLE_LOAD_INIT,
      types.TABLE_REMOVE_FILTER_ITEM,
      types.TABLE_SELECT_FILTER_ITEM,
      types.TABLE_TOGGLE_FILTER_OPERATOR,
    ],
    table.loadSaga
  ),
  takeEvery(types.TABLE_EXPORT_SELECTION, table.exportSelectionSaga),
  takeEvery(types.TABLE_EXPORT_SHOW_DIALOG, table.exportShowDialogSaga),
  takeLatest(types.TABLE_GET_INSIGHTS_URL, table.getInsightsUrlSaga),
  takeLatest(types.TABLE_GET_LAST_NOTE, table.getLastNoteSaga),
  takeLatest(types.TABLE_GET_MATCHING, table.getMatchingSaga),
  takeEvery(types.TABLE_RELOAD, table.reloadSaga),
  takeEvery(types.TABLE_SAVE_CURRENT_FILTER, table.saveCurrentFilterSaga),
  takeEvery(types.TABLE_SAVE_MATCHING, table.saveMatchingSaga),
  takeEvery(types.TABLE_SET_FILTER_LIST, table.setFilterListSaga),
  takeEvery(types.TABLE_SET_SAVED_FILTER, table.setSavedFilterSaga),
]

const watchTask = [
  takeEvery(types.TASK_CREATE, task.createSaga),
  takeEvery(types.TASK_COMPLETED, task.completedSaga),
  takeEvery(types.TASK_GET_ITEM, task.getItemSaga),
  takeEvery(types.TASK_GET_SHARED_TASKS, task.getSharedTasksSaga),
  takeLatest(types.TASK_LOAD_MY_TASKS, task.loadMyTasksSaga),
  takeLatest(types.TASK_LOAD_TASKS, task.loadTasksSaga),
  takeEvery(types.TASK_SET_TAB, task.setTabSaga),
  takeEvery(types.TASK_SHARED_TASKS_CHANGED, task.sharedTasksChangedSaga),
  takeEvery(types.TASK_UPDATE, task.updateSaga),
]

const watchTemplate = [
  takeEvery(types.TEMPLATE_ACTIVATE, template.activateSaga),
  takeEvery(types.TEMPLATE_ADD_ATTACHMENT, template.createAttachmentSaga),
  takeEvery(types.TEMPLATE_CREATE, template.createSaga),
  takeEvery(types.TEMPLATE_DEACTIVATE, template.deactivateSaga),
  takeEvery(types.TEMPLATE_GET_NEW, template.getNewSaga),
  takeEvery(types.TEMPLATE_GET, template.getSaga),
  takeEvery(types.TEMPLATE_MEDIA_CREATE, template.mediaCreateSaga),
  takeEvery(types.TEMPLATE_MEDIA_GET, template.mediaGetSaga),
  takeEvery(types.TEMPLATE_MEDIA_LOAD, template.mediaLoadSaga),
  takeEvery(types.TEMPLATE_MEDIA_UPDATE, template.mediaUpdateSaga),
  takeEvery(types.TEMPLATE_MEDIA_REMOVE, template.mediaRemoveSaga),
  takeEvery(types.TEMPLATE_UPDATE, template.updateSaga),
]

const watchUser = [
  takeEvery(types.USER_ACTIVATE, user.activateSaga),
  takeEvery(types.USER_CREATE, user.createSaga),
  takeEvery(types.USER_CREATE_VIEWER, user.createViewerSaga),
  takeEvery(types.USER_DEACTIVATE, user.deactivateSaga),
  takeEvery(types.USER_DELETE_MY_PHOTO, user.deleteMyPhotoSaga),
  takeEvery(types.USER_EDIT, user.editSaga),
  takeEvery(types.USER_GET_MY, user.getMySaga),
  takeLeading(types.USER_INVITE, user.inviteSaga),
  takeEvery(types.USER_LOG_LOCATION, user.logLocationSaga),
  takeEvery(types.USER_PUT_MY, user.putMySaga),
  takeEvery(types.USER_PUT_MY_THEME, user.putMyThemeSaga),
  takeEvery(types.USER_REMOVE_VIEWER, user.removeViewerSaga),
  takeEvery(types.USER_SAVE_DEFAULT_TEMPLATE, user.saveDefaultTemplateSaga),
  takeEvery(types.USER_SAVE_FILTER, user.saveFilterSaga),
  takeEvery(types.USER_SIGNOUT, user.signoutSaga),
  takeEvery(types.USER_TERMINATE, user.terminateSaga),
  takeEvery(types.USER_UPDATE_VIEWER, user.updateViewerSaga),
  takeEvery(types.USER_UPLOAD_MY_PHOTO, user.uploadMyPhotoSaga),
]

export default function* rootSaga() {
  yield all([
    ...watchActivity,
    ...watchAddress,
    ...watchApp,
    ...watchApply,
    ...watchAuth,
    ...watchAutomation,
    ...watchCache,
    ...watchCampaign,
    ...watchCandidate,
    ...watchCertificate,
    ...watchCompany,
    ...watchDashboard,
    ...watchDeletion,
    ...watchEmail,
    ...watchEntity,
    ...watchEvent,
    ...watchExports,
    ...watchFunnel,
    ...watchJob,
    ...watchLookup,
    ...watchNotification,
    ...watchPdf,
    ...watchQuick,
    ...watchProject,
    ...watchRelation,
    ...watchSearch,
    ...watchShare,
    ...watchShell,
    ...watchTable,
    ...watchTask,
    ...watchTemplate,
    ...watchUser,
  ])
}
