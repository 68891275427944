const id = (name) => `@forceflow/${name}`

// activity
export const ACTIVITY_GET_ACTIVITIES = id('activity_get_activities')
export const ACTIVITY_GET_ACTIVITIES_SUCCEED = id(
  'activity_get_activities_succeed'
)
export const ACTIVITY_SET_ACTIVE_USER = id('activity_set_active_user')
export const ACTIVITY_SET_FILTER = id('activity_set_filter')

// address
export const ADDRESS_GET_BY_LINE = id('address_get_by_line')
export const ADDRESS_GET_BY_ZIP = id('address_get_by_zip')

// app
export const APP_CHANGE_LANGUAGE = id('app_change_language')
export const APP_DOCK_NAVBAR = id('app_dock_navbar')
export const APP_FOLD_PANELS = id('app_fold_panels')
export const APP_GOTO_URL = id('app_goto_url')
export const APP_OPEN_NAVBAR = id('app_open_navbar')
export const APP_REGISTER_PANEL = id('app_register_panel')
export const APP_SEND_ERROR = id('app_send error')
export const APP_SET_DELIMITERS = id('app_set_delimiters')
export const APP_SET_LANGUAGE = id('app_set_language')
export const APP_SET_ONLINE = id('app_set_online')
export const APP_SET_PANEL_EXPANDED = id('app_set_panel_expanded')
export const APP_SET_VERSION = id('app_set_version')
export const APP_SHOW_NEW_VERSION = id('app_show_new_version')
export const APP_START_VERSION_CHECK = id('app_start_version_check')
export const APP_UNFOLD_PANELS = id('app_unfold_panels')

// apply
export const APPLY_GET_DATA = id('apply_get_data')
export const APPLY_POST_DATA = id('apply_post_data')
export const APPLY_SET_DATA = id('apply_set_data')

// auth
export const AUTH_ACTIVATE = id('auth_activate')
export const AUTH_ACTIVATE_INFO = id('auth_activate_info')
export const AUTH_ACTIVATE_INFO_SUCCEED = id('auth_activate_info_succeed')
export const AUTH_ACTIVATE_SET_RESEND = id('auth_activate_set_resend')
export const AUTH_CANCEL_CHANGE_EMAIL = id('auth_cancel_change_email')
export const AUTH_CANCEL_CHANGE_EMAIL_SUCCEED = id(
  'auth_cancel_change_email_succeed'
)
export const AUTH_CHANGE_COMPANY = id('auth_change_company')
export const AUTH_CHANGE_COMPANY_SUCCEED = id('auth_change_company_succeed')
export const AUTH_CHANGE_EMAIL = id('auth_change_email')
export const AUTH_CHANGE_PASSWORD = id('auth_change_password')
export const AUTH_CHECK_EMAIL = id('auth_check_email')
export const AUTH_CHECK_PASSWORD = id('auth_check_password')
export const AUTH_COMPANY_SET_SSO = id('auth_company_set_sso')
export const AUTH_CONNECT_NYLAS = id('auth_connect_nylas')
export const AUTH_FORCE_REFRESH = id('auth_force_refresh')
export const AUTH_GET_ACCOUNT_STATUS = id('auth_get_account_status')
export const AUTH_GET_ACCOUNT_STATUS_SUCCEED = id(
  'auth_get_account_status_succeed'
)
export const AUTH_GET_ACCOUNTS = id('auth_get_accounts')
export const AUTH_GET_ACCOUNTS_SUCCEED = id('auth_get_accounts_succeed')
export const AUTH_GET_PARTNER = id('auth_get_partner')
export const AUTH_GET_QRCODE = id('auth_get_qrcode')
export const AUTH_GET_SESSIONS = id('auth_get_sessions')
export const AUTH_GET_SUBSCRIPTION = id('auth_get_subscription')
export const AUTH_INITIALIZE_SYSTEM = id('auth_initialize_system')
export const AUTH_INITIALIZE_SYSTEM_SUCCEED = id(
  'auth_initialize_system_succeed'
)
export const AUTH_PASSWORDLINK = id('auth_passwordlink')
export const AUTH_PREVIEW_MODE = id('auth_preview_mode')
export const AUTH_PREVIEW_MODE_SUCCEED = id('auth_preview_mode_succeed')
export const AUTH_PUT_SUBSCRIPTION = id('auth_put_subscription')
export const AUTH_REGISTER_NYLAS = id('auth_register_nylas')
export const AUTH_REGISTER_NYLAS_BUSY = id('auth_register_nylas_busy')
export const AUTH_REMOVE_ACCOUNT = id('auth_remove_account')
export const AUTH_REMOVE_EMAIL2 = id('auth_remove_email2')
export const AUTH_RESET_PASSWORD = id('auth_reset_password')
export const AUTH_SEND_RECOVERY_CODE = id('auth_send_recovery_code')
export const AUTH_SEND_VERIFICATION_CODE = id('auth_send_verification_code')
export const AUTH_SESSION_INIT = id('auth_init_session')
export const AUTH_SESSION = id('auth_session')
export const AUTH_SET_AUTHENTICATED = id('auth_set_authenticated')
export const AUTH_SET_COMPANY_SEARCH = id('auth_set_company_search')
export const AUTH_SET_EMAIL = id('auth_set_email')
export const AUTH_SET_NEW_EMAIL = id('auth_set_new_email')
export const AUTH_SET_PARTNER = id('auth_set_partner')
export const AUTH_SET_QRCODE = id('auth_set_qrcode')
export const AUTH_SET_SESSIONS = id('auth_set_sessions')
export const AUTH_SET_SSO = id('auth_set_sso')
export const AUTH_SET_SUBSCRIPTION = id('auth_set_subscription')
export const AUTH_SET_TWOFACTOR_ENABLED = id('auth_set_twofactor_enabled')
export const AUTH_SHOW_SSO = id('auth_show_sso')
export const AUTH_SIGNIN = id('auth_signin')
export const AUTH_SIGNIN_SUCCEED = id('auth_signin_succeed')
export const AUTH_SIGNOUT = id('auth_signout')
export const AUTH_SIGNOUT_SUCCEED = id('auth_signout_succeed')
export const AUTH_SIGNUP = id('auth_signup')
export const AUTH_SOURCES_LOAD = id('auth_sources_load')
export const AUTH_SOURCES_REMOVE = id('auth_sources_remove')
export const AUTH_SOURCES_RENAME = id('auth_sources_rename')
export const AUTH_SOURCES_SET_DATA = id('auth_sources_set_data')
export const AUTH_SOURCES_SET_FILTER = id('auth_sources_set_filter')
export const AUTH_SOURCES_SET_LOADING = id('auth_sources_set_loading')
export const AUTH_SSO_SIGNIN = id('auth_sso_signin')
export const AUTH_SSO_SIGNIN_FAILED = id('auth_sso_signin_failed')
export const AUTH_SSO_SIGNIN_SUCCEED = id('auth_sso_signin_succeed')
export const AUTH_STOP_SUBSCRIPTION = id('auth_stop_subscription')
export const AUTH_TAGS_LOAD = id('auth_tags_load')
export const AUTH_TAGS_REMOVE = id('auth_tags_remove')
export const AUTH_TAGS_RENAME = id('auth_tags_rename')
export const AUTH_TAGS_SET_DATA = id('auth_tags_set_data')
export const AUTH_TAGS_SET_FILTER = id('auth_tags_set_filter')
export const AUTH_TAGS_SET_LOADING = id('auth_tags_set_loading')
export const AUTH_TWOFACTOR_ENABLE = id('auth_twofactor_enable')
export const AUTH_UPDATE_ACCOUNT = id('auth_update_account')
export const AUTH_UPDATE_INFO = id('auth_update_info')
export const AUTH_VALIDATE_EMAIL = id('auth_validate_email')
export const AUTH_VALIDATE_TOKEN = id('auth_validate_token')

// automation
export const AUTOMATION_GET_LIST = id('automation_get_list')
export const AUTOMATION_GET_LIST_SUCCEED = id('automation_get_list_succeed')
export const AUTOMATION_GET_LOG = id('automation_get_log')
export const AUTOMATION_GET_LOG_SUCCEED = id('automation_get_log_succeed')
export const AUTOMATION_SET_LOADING = id('automation_set_loading')
export const AUTOMATION_START = id('automation_start')
export const AUTOMATION_STOP = id('automation_stop')
export const AUTOMATION_UPDATE_SETTINGS = id('automation_update_settings')

// cache
export const CACHE_ADD_COMPANIES = id('cache_add_companies')
export const CACHE_ADD_FILTERS = id('cache_add_filters')
export const CACHE_ADD_LOOKUPS = id('cache_add_lookups')
export const CACHE_ADD_TAG = id('cache_add_tag')
export const CACHE_ADD_TAGS = id('cache_add_tags')
export const CACHE_ADD_TEMPLATES = id('cache_add_templates')
export const CACHE_ADD_USERS = id('cache_add_users')
export const CACHE_ADD_VIEWERS = id('cache_add_viewers')
export const CACHE_CLEAR_TAGS = id('cache_clear_tags')
export const CACHE_ENTITIES_SET_USER_FILTER = id(
  'cache_entities_set_user_filter'
)
export const CACHE_ENTITIES_SET_USER_FILTER_TYPE = id(
  'cache_entities_set_user_filter_type'
)
export const CACHE_ENTITIES_SET_COMPANY_FILTER = id(
  'cache_entities_set_company_filter'
)
export const CACHE_FILTERS_LOADED = id('cache_filters_loaded')
export const CACHE_LOAD_COMPANIES = id('cache_load_companies')
export const CACHE_LOAD_COMPANIES_FINISHED = id('cache_load_companies_finished')
export const CACHE_LOAD_FILTERS = id('cache_load_filters')
export const CACHE_LOAD_LOOKUPS = id('cache_load_lookups')
export const CACHE_LOAD_TAGS = id('cache_load_tags')
export const CACHE_LOAD_TEMPLATES = id('cache_load_templates')
export const CACHE_LOAD_USERS = id('cache_load_users')
export const CACHE_LOAD_USERS_FINISHED = id('cache_load_users_finished')
export const CACHE_LOAD_VIEWERS = id('cache_load_viewers')
export const CACHE_LOADING_COMPANIES = id('cache_loading_companies')
export const CACHE_LOADING_TEMPLATES = id('cache_loading_templates')
export const CACHE_LOADING_USERS = id('cache_loading_users')
export const CACHE_REMOVE_TAG = id('cache_remove_tag')

// campaign
export const CAMPAIGN_ADD_CHANNEL = id('campaign_add_channel')
export const CAMPAIGN_GET_CHANNELS = id('campaign_get_channels')
export const CAMPAIGN_GET_CHANNELS_SUCCEED = id('campaign_get_channels_succeed')
export const CAMPAIGN_LOAD_LISTS = id('campaign_load_lists')
export const CAMPAIGN_LOAD_LISTS_SUCCEED = id('campaign_load_lists_succeed')
export const CAMPAIGN_REFRESH_CHANNELS = id('campaign_refresh_channels')
export const CAMPAIGN_REMOVE_CHANNEL = id('campaign_remove_channel')
export const CAMPAIGN_SET_FAVORITE = id('campaign_set_favorite')
export const CAMPAIGN_SET_FILTER = id('campaign_set_filter')
export const CAMPAIGN_SET_LOADING = id('campaign_set_loading')
export const CAMPAIGN_SET_ORDER = id('campaign_set_order')
export const CAMPAIGN_SET_SEARCH = id('campaign_set_search')
export const CAMPAIGN_SET_SKIP = id('campaign_set_skip')
export const CAMPAIGN_SET_TAB = id('campaign_set_tab')
export const CAMPAIGN_SHOW_FILTER = id('campaign_show_filter')
export const CAMPAIGN_TOGGLE_CART = id('campaign_toggle_cart')
export const CAMPAIGN_UNSET_FAVORITE = id('campaign_unset_favorite')

// candidate
export const CANDIDATE_ADD_DUPLICATES = id('candidate_add_duplicates')
export const CANDIDATE_ADD_EDUCATION = id('candidate_add_education')
export const CANDIDATE_ADD_EDUCATION_SUCCEED = id(
  'candidate_add_education_succeed'
)
export const CANDIDATE_ADD_EMPLOYMENT = id('candidate_add_employment')
export const CANDIDATE_ADD_EMPLOYMENT_SUCCEED = id(
  'candidate_add_employment_succeed'
)
export const CANDIDATE_ADD_SKILL = id('candidate_add_skill')
export const CANDIDATE_ADD_SKILL_SUCCEED = id('candidate_add_skill_succeed')
export const CANDIDATE_ADD_SHORTLIST = id('candidate_add_shortlist')
export const CANDIDATE_ADD_SOURCES = id('candidate_add_sources')
export const CANDIDATE_ADD_TALENTPOOL = id('candidate_add_talentpool')
export const CANDIDATE_CHECK_DUPLICATE = id('candidate_check_duplicate')
export const CANDIDATE_GET_COMPANY_SHARES = id('candidate_get_company_shares')
export const CANDIDATE_GET_COMPANY_SHARES_SUCCEED = id(
  'candidate_get_company_shares_succeed'
)
export const CANDIDATE_CREATE_SHARE = id('candidate_create_share')
export const CANDIDATE_DELETE_ALTERNATIVE = id('candidate_delete_alternative')
export const CANDIDATE_DELETE_ALTERNATIVE_SUCCEED = id(
  'candidate_delete_alternative_succeed'
)
export const CANDIDATE_DELETE_COVER = id('candidate_delete_cover')
export const CANDIDATE_DELETE_COVER_SUCCEED = id(
  'candidate_delete_cover_succeed'
)
export const CANDIDATE_DELETE_EDUCATION = id('candidate_delete_education')
export const CANDIDATE_DELETE_EDUCATION_SUCCEED = id(
  'candidate_delete_education_succeed'
)
export const CANDIDATE_DELETE_EMPLOYMENT = id('candidate_delete_employment')
export const CANDIDATE_DELETE_EMPLOYMENT_SUCCEED = id(
  'candidate_delete_employment_succeed'
)
export const CANDIDATE_DELETE_PHOTO = id('candidate_delete_photo')
export const CANDIDATE_DELETE_PHOTO_SUCCEED = id(
  'candidate_delete_photo_succeed'
)
export const CANDIDATE_DELETE_RESUME = id('candidate_resume_photo')
export const CANDIDATE_DELETE_RESUME_SUCCEED = id(
  'candidate_delete_resume_succeed'
)
export const CANDIDATE_DELETE_SKILL = id('candidate_delete_skill')
export const CANDIDATE_DELETE_SKILL_SUCCEED = id(
  'candidate_delete_skill_succeed'
)
export const CANDIDATE_EXTEND_GDPR = id('candidate_extend_gdpr')
export const CANDIDATE_GET_CREDITS = id('candidate_get_credits')
export const CANDIDATE_GET_CREDITS_SUCCEED = id('candidate_get_credits_succeed')
export const CANDIDATE_GET_GDPR_INFO = id('candidate_get_gdpr_info')
export const CANDIDATE_GET_GDPR_INFO_SUCCEED = id(
  'candidate_get_gdpr_info_succeed'
)
export const CANDIDATE_GET_JOBS = id('candidate_get_jobs')
export const CANDIDATE_GET_JOBS_INIT = id('candidate_get_jobs_init')
export const CANDIDATE_GET_JSON = id('candidate_get_json')
export const CANDIDATE_GET_JSON_SUCCEED = id('candidate_get_json_succeed')
export const CANDIDATE_GET_PROFILE_SHARES = id('candidate_get_profile_shares')
export const CANDIDATE_GET_PROFILE_SHARES_SUCCEED = id(
  'candidate_get_profile_shares_succeed'
)
export const CANDIDATE_GET_PROJECTS = id('candidate_get_projects')
export const CANDIDATE_GET_PROJECTS_SUCCEED = id(
  'candidate_get_projects_succeed'
)
export const CANDIDATE_GET_SHARE_DEFAULTS = id('candidate_get_share_defaults')
export const CANDIDATE_GET_SHARES = id('candidate_get_shares')
export const CANDIDATE_GET_SHARES_SUCCEED = id('candidate_get_shares_succeed')
export const CANDIDATE_GET_SHARE_VIEWS = id('candidate_get_share_views')
export const CANDIDATE_GET_SHARE_VIEWS_SUCCEED = id(
  'candidate_get_share_views_succeed'
)
export const CANDIDATE_GET_SHORTLIST = id('candidate_get_shortlist')
export const CANDIDATE_GET_SHORTLIST_INIT = id('candidate_get_shortlist_init')
export const CANDIDATE_GET_VIEWERS = id('candidate_get_viewers')
export const CANDIDATE_LOAD_DUPLICATES = id('candidate_load_duplicates')
export const CANDIDATE_LOAD_SOURCES = id('candidate_load_sources')
export const CANDIDATE_MERGE = id('candidate_merge')
export const CANDIDATE_MERGE_ADD_INFO = id('candidate_merge_add_info')
export const CANDIDATE_MERGE_GET_INFO = id('candidate_merge_get_info')
export const CANDIDATE_MERGE_RESET = id('candidate_merge_reset')
export const CANDIDATE_MERGE_SELECT = id('candidate_merge_select')
export const CANDIDATE_PARSE_RESUME = id('candidate_parse_resume')
export const CANDIDATE_PARSE_EXISTING_RESUME = id(
  'candidate_parse_existing_resume'
)
export const CANDIDATE_PDF_ZOOMIN = id('candidate_pdf_zoomin')
export const CANDIDATE_PDF_ZOOMOUT = id('candidate_pdf_zoomout')
export const CANDIDATE_PROCESS_REQUEST_GDPR = id(
  'candidate_process_request_gdpr'
)
export const CANDIDATE_SET_PROJECTS_LOADED = id('candidate_set_projects_loaded')
export const CANDIDATE_REMOVE_SHARE = id('candidate_remove_share')
export const CANDIDATE_REMOVE_SHORTLIST = id('candidate_remove_shortlist')
export const CANDIDATE_REMOVE_TALENTPOOL = id('candidate_remove_talentpool')
export const CANDIDATE_SELECT_JOBS = id('candidate_select_jobs')
export const CANDIDATE_SELECTBAR_SET_JOBID = id('candidate_selectbar_set_jobid')
export const CANDIDATE_SELECTBAR_SET_MATCHING = id(
  'candidate_selectbar_set_matching'
)
export const CANDIDATE_SEND_GDPR_CONSENT = id('candidate_send_gdpr_consent')
export const CANDIDATE_SEND_GDPR_REQUEST = id('candidate_send_gdpr_request')
export const CANDIDATE_SEND_SHARE = id('candidate_send_share')
export const CANDIDATE_SET_BARBUTTON = id('candidate_set_barbutton')
export const CANDIDATE_SET_DATA = id('candidate_set_data')
export const CANDIDATE_SET_JOBS_FILTER = id('candidate_set_jobs_filter')
export const CANDIDATE_SET_JOBS_LOADED = id('candidate_set_jobs_loaded')
export const CANDIDATE_SET_PARSING_BUSY = id('candidate_set_parsing_busy')
export const CANDIDATE_SET_SHARE_DEFAULTS = id('candidate_set_share_defaults')
export const CANDIDATE_SET_SOURCES_DIRTY = id('candidate_set_sources_dirty')
export const CANDIDATE_SET_UPLOADING = id('candidate_set_uploading')
export const CANDIDATE_SHORTLIST_CLEAR_SELECT = id(
  'candidate_shortlist_clear_select'
)
export const CANDIDATE_SHORTLIST_DESELECT = id('candidate_shortlist_deselect')
export const CANDIDATE_SHORTLIST_INVERSE_SELECTION = id(
  'candidate_shortlist_inverse_selection'
)
export const CANDIDATE_SHORTLIST_SELECT = id('candidate_shortlist_select')
export const CANDIDATE_SHOW_SHARES = id('candidate_show_shares')
export const CANDIDATE_TALENTPOOL_ADD = id('candidate_talentpool_add')
export const CANDIDATE_TALENTPOOL_REMOVE = id('candidate_talentpool_remove')
export const CANDIDATE_UPDATE_EDUCATION = id('candidate_update_education')
export const CANDIDATE_UPDATE_EDUCATION_SUCCEED = id(
  'candidate_update_education_succeed'
)
export const CANDIDATE_UPDATE_EMPLOYMENT = id('candidate_update_employment')
export const CANDIDATE_UPDATE_EMPLOYMENT_SUCCEED = id(
  'candidate_update_employment_succeed'
)
export const CANDIDATE_UPDATE_RATING = id('candidate_update_rating')
export const CANDIDATE_UPDATE_SHARE = id('candidate_update_share')
export const CANDIDATE_UPDATE_SKILL = id('candidate_update_skill')
export const CANDIDATE_UPDATE_SKILL_SUCCEED = id(
  'candidate_update_skill_succeed'
)
export const CANDIDATE_UPGRADE_RESUME = id('candidate_upgrade_resume')
export const CANDIDATE_UPLOAD_PHOTO = id('candidate_upload_photo')
export const CANDIDATE_UPLOAD_PHOTO_SUCCEED = id(
  'candidate_upload_photo_succeed'
)
export const CANDIDATE_UPLOAD_ALTERNATIVE = id('candidate_upload_alternative')
export const CANDIDATE_UPLOAD_COVER = id('candidate_upload_cover')
export const CANDIDATE_UPLOAD_RESUME = id('candidate_upload_resume')

// certificate
export const CERTIFICATE_ADD_CERTIFICATE = id('certificate_add_certificate')
export const CERTIFICATE_ADD_CERTIFICATES = id('certificate_add_certificates')
export const CERTIFICATE_DELETE_CERTIFICATE = id(
  'certificate_delete_certificate'
)
export const CERTIFICATE_RESET = id('certificate_reset')
export const CERTIFICATE_SET_BUSY = id('certificate_set_busy')
export const CERTIFICATE_SET_VALUES = id('certificate_set_values')
export const CERTIFICATE_SET_SELECTED_INDEX = id(
  'certificate_set_selected_index'
)

// company
export const COMPANY_ACTIVATE = id('company_activate')
export const COMPANY_CLEAR_CACHE = id('company_clear_cache')
export const COMPANY_CONNECT_ROBIN = id('company_connect_robin')
export const COMPANY_CREATE = id('company_create')
export const COMPANY_DEACTIVATE = id('company_deactivate')
export const COMPANY_EDIT = id('company_edit')
export const COMPANY_GET_HIRING = id('company_get_hiring')
export const COMPANY_GET_HIRING_SUCCEED = id('company_get_hiring_succeed')
export const COMPANY_GET_MY = id('company_get_my')
export const COMPANY_GET_PHASES = id('company_get_phases')
export const COMPANY_GET_PHASES_SUCCEED = id('company_get_phases_succeed')
export const COMPANY_GET_WEBHOOKS = id('company_get_webhooks')
export const COMPANY_GET_WEBHOOKS_SUCCEED = id('company_get_webhooks_succeed')
export const COMPANY_PUT_MY = id('company_put_my')
export const COMPANY_DELETE_MY_PHOTO = id('company_delete_my_photo')
export const COMPANY_DELETE_MY_PHOTO_SUCCEED = id(
  'company_delete_my_photo_succeed'
)
export const COMPANY_REMOVE_WEBHOOK = id('company_remove_webhook')
export const COMPANY_RENEW_TOKEN = id('company_renew_token')
export const COMPANY_SET_MY_DATA = id('company_set_my_data')
export const COMPANY_SET_SAVING = id('company_set_saving')
export const COMPANY_TEST_WEBHOOK = id('company_test_webhook')
export const COMPANY_TEST_WEBHOOK_RESULT = id('company_test_webhook_result')
export const COMPANY_UPDATE_PHASE = id('company_update_phase')
export const COMPANY_UPDATE_SCOPE = id('company_update_scope')
export const COMPANY_UPDATE_SETTING = id('company_update_setting')
export const COMPANY_UPDATE_TENANT_NAME = id('company_update_tenant_name')
export const COMPANY_UPDATE_WEBHOOK = id('company_update_webhook')
export const COMPANY_UPLOAD_MY_PHOTO = id('company_upload_my_photo')
export const COMPANY_UPLOAD_MY_PHOTO_SUCCEED = id(
  'company_upload_my_photo_succeed'
)

// dashboard
export const DASHBOARD_GET_DEFINITION = id('dashboard_get_definition')
export const DASHBOARD_GOTO_TAB = id('dashboard_goto_tab')
export const DASHBOARD_SET_DEFINITION = id('dashboard_set_definition')
export const DASHBOARD_SET_DEFINITION_LOADED = id(
  'dashboard_set_definition_loaded'
)
export const DASHBOARD_SET_LOADING = id('dashboard_set_loading')
export const DASHBOARD_SET_POSITION_TOP = id('dashboard_set_position_top')
export const DASHBOARD_SET_TAB_NAME = id('dashboard_set_tab_name')
export const DASHBOARD_WIDGET_ADD = id('dashboard_widget_add')
export const DASHBOARD_WIDGET_GET = id('dashboard_widget_get')
export const DASHBOARD_WIDGET_LEGEND_VISIBLE = id(
  'dashboard_widget_legend_visible'
)
export const DASHBOARD_WIDGET_LEGEND_INVISIBLE = id(
  'dashboard_widget_legend_invisible'
)
export const DASHBOARD_WIDGET_MAXIMIZE = id('dashboard_widget_maximize')
export const DASHBOARD_WIDGET_MOVE = id('dashboard_widget_move')
export const DASHBOARD_WIDGET_REMOVE = id('dashboard_widget_remove')
export const DASHBOARD_WIDGET_SET = id('dashboard_widget_set')
export const DASHBOARD_WIDGET_SET_SETTING = id('dasboard_widget_set_setting')
export const DASHBOARD_WIDGET_SIZE = id('dasboard_widget_size')

// deletion
export const DELETION_GET_NUMBER = id('deletion_get_number')
export const DELETION_RESTORE_ITEMS = id('deletion_restore_items')
export const DELETION_SET_NUMBER = id('deletion_set_number')

// email
export const EMAIL_ADD_ATTACHMENT = id('email_add_attachment')
export const EMAIL_ADD_ATTACHMENT_SUCCEED = id('email_add_attachment_succeed')
export const EMAIL_ADD_CONTEXT = id('email_add_context')
export const EMAIL_ADD_RECIPIENTS = id('email_add_recipients')
export const EMAIL_CLEAR_ATTACHMENTS = id('email_clear_attachments')
export const EMAIL_CONNECT = id('email_connect')
export const EMAIL_CREATE_CANDIDATE = id('email_create_candidate')
export const EMAIL_DECREMENT_UPLOADING = id('email_decrement_uploading')
export const EMAIL_DELETE_ATTACHMENT = id('email_delete_attachment')
export const EMAIL_DELETE_ATTACHMENT_SUCCEED = id(
  'email_delete_attachment_succeed'
)
export const EMAIL_DELETE_DRAFT = id('email_delete_draft')
export const EMAIL_GET_TEMPLATE = id('email_get_template')
export const EMAIL_GOTO_EMAILS_SENT = id('email_goto_emails_sent')
export const EMAIL_INCREMENT_UPLOADING = id('email_increment_uploading')
export const EMAIL_REMOVE_CONTEXT = id('email_remove_context')
export const EMAIL_REMOVE_TEMPLATE_ATTACHMENTS = id(
  'email_remove_template_attachments'
)
export const EMAIL_RESET_UPLOADING = id('email_reset_uploading')
export const EMAIL_SAVE_JOB = id('email_save_job')
export const EMAIL_SET_JOB_NAME = id('email_set_job_name')
export const EMAIL_SEND = id('email_send')
export const EMAIL_SEND_BULK = id('email_send_bulk')
export const EMAIL_SHOW_BULKEMAIL = id('email_show_bulkemail')
export const EMAIL_SHOW_NEWEMAIL = id('email_show_newemail')
export const EMAIL_TRANSLATE = id('email_translate')
export const EMAIL_VIEW_GET = id('email_view_get')
// entity
export const ENTITY_ACTIVITY_SET_LOADING = id('entity_activity_set_loading')
export const ENTITY_ADD_NOTE = id('entity_add_note')
export const ENTITY_ADD_TAG_INIT = id('entity_add_tag_init')
export const ENTITY_ADD_TAG = id('entity_add_tag')
export const ENTITY_ANONIMYZE_ITEM = id('entity_anonymize_tag')
export const ENTITY_CHANGE_COMPANYID = id('entity_change_companyid')
export const ENTITY_CREATE = id('entity_create')
export const ENTITY_CREATED = id('entity_created')
export const ENTITY_DELETE_CERTIFICATE = id('entity_delete_certificate')
export const ENTITY_DELETE_ITEM = id('entity_delete_item')
export const ENTITY_DELETE_NOTE = id('entity_delete_note')
export const ENTITY_DELETE_UNSAVED_NOTE = id('entity_delete_unsaved_note')
export const ENTITY_DESELECT_ITEMS = id('entity_deselect_items')
export const ENTITY_DOWNLOAD_NOTE_ATTACHMENT = id(
  'entity_download_note_attachment'
)
export const ENTITY_GET = id('entity_get')
export const ENTITY_GET_ACTIVITIES = id('entity_get_activities')
export const ENTITY_GET_ACTIVITIES_SUCCEED = id('entity_get_activities_succeed')
export const ENTITY_GET_ATTACHMENTS = id('entity_get_attachments')
export const ENTITY_GET_ATTACHMENTS_INIT = id('entity_get_attachments_init')
export const ENTITY_GET_CERTIFICATES = id('entity_get_certificates')
export const ENTITY_GET_CERTIFICATE_URL = id('entity_get_certificate_url')
export const ENTITY_GET_CERTIFICATE_URL_SUCCEED = id(
  'entity_get_certificate_url_succeed'
)
export const ENTITY_GET_CERTIFICATES_SUCCEED = id(
  'entity_get_certificates_succeed'
)
export const ENTITY_GET_EMAILS = id('entity_get_emails')
export const ENTITY_GET_EMAILS_SUCCEED = id('entity_get_emails_succeed')
export const ENTITY_GET_EVENTS = id('entity_get_events')
export const ENTITY_GET_EVENTS_SUCCEED = id('entity_get_events_succeed')
export const ENTITY_GET_FILES = id('entity_get_files')
export const ENTITY_GET_INSIGHTS_URL = id('entity_get_insights_url')
export const ENTITY_GET_LAST_NOTE = id('entity_get_last_note')
export const ENTITY_GET_LAST_NOTE_INIT = id('entity_get_last_note_init')
export const ENTITY_GET_NOTES = id('entity_get_notes')
export const ENTITY_GET_NOTES_INIT = id('entity_get_notes_init')
export const ENTITY_GET_NOTES_USERS = id('entity_get_notes_users')
export const ENTITY_GET_NOTES_USERS_SUCCEED = id(
  'entity_get_notes_users_succeed'
)
export const ENTITY_GET_SUCCEED = id('entity_get_succeed')
export const ENTITY_GET_TASKS = id('entity_get_tasks')
export const ENTITY_GET_TASKS_SUCCEED = id('entity_get_tasks_succeed')
export const ENTITY_GET_UNSAVED_NOTE = id('entity_get_unsaved_note')
export const ENTITY_EXPAND_NOTE = id('entity_expand_note')
export const ENTITY_MARK = id('entity_mark')
export const ENTITY_NOTE_GET_VIEWERS = id('entity_note_get_viewers')
export const ENTITY_NOTE_SET_VIEWERS = id('entity_note_set_viewers')
export const ENTITY_NOTES_COLLAPSE_ALL = id('entity_notes_collapse_all')
export const ENTITY_NOTES_EXPAND_ALL = id('entity_notes_expand_all')
export const ENTITY_NOTES_SET_BACK_URL = id('entity_notes_set_back_url')
export const ENTITY_NOTES_SET_ORDER = id('entity_notes_set_order')
export const ENTITY_PUT = id('entity_put')
export const ENTITY_REFRESH = id('entity_refresh')
export const ENTITY_REMOVE_TAG_INIT = id('entity_remove_tag_init')
export const ENTITY_REMOVE_TAG = id('entity_remove_tag')
export const ENTITY_RESET_DATA = id('entity_reset_data')
export const ENTITY_RESTORE = id('entity_restore')
export const ENTITY_SAVE_UNSAVED_NOTE = id('entity_save_unsaved_note')
export const ENTITY_SELECT_CERTIFICATE = id('entity_select_certificate')
export const ENTITY_SET_ACTIVITIES_FILTER = id('entity_set_activities_filter')
export const ENTITY_SET_CONTENTOVERFLOWX = id('entity_set_contentoverflowx')
export const ENTITY_SET_CONTENTOVERFLOWY = id('entity_set_contentoverflowy')
export const ENTITY_SET_DATA = id('entity_set_data')
export const ENTITY_SET_DATA_ITEM = id('entity_set_data_item')
export const ENTITY_SET_EDITFORM = id('entity_set_editform')
export const ENTITY_SET_EDITMODE = id('entity_set_editmode')
export const ENTITY_SET_EMAILS_FILTER = id('entity_set_emails_filter')
export const ENTITY_SET_EMAILS_FILTER_CANDIDATE = id(
  'entity_set_emails_filter_candidate'
)
export const ENTITY_SET_EMAILS_LOADED = id('entity_set_emails_loaded')
export const ENTITY_SET_FILES_LOADING = id('entity_set_files_loading')
export const ENTITY_SET_HASNOTES = id('entity_set_hasnotes')
export const ENTITY_SET_ID = id('entity_set_id')
export const ENTITY_SET_INSIGHTS = id('entity_set_insights')
export const ENTITY_SET_LOADED_OK = id('entity_set_loaded_ok')
export const ENTITY_SET_LOADING = id('entity_set_loading')
export const ENTITY_SET_NOTE_SECURED = id('entity_set_note_secured')
export const ENTITY_SET_NOTE_SECURED_SUCCEED = id(
  'entity_set_note_secured_succeed'
)
export const ENTITY_SET_NOTE_VIEWERS = id('entity_set_note_viewers')
export const ENTITY_SET_NOTES_FILTER = id('entity_set_notes_filter')
export const ENTITY_SET_NOTES_FILTER_TYPE = id('entity_set_notes_filter_type')
export const ENTITY_SET_NOT_FOUND = id('entity_set_not_found')
export const ENTITY_SET_PARTIAL_DATA = id('entity_set_partial_data')
export const ENTITY_SET_PREVLOCATION = id('entity_set_prevlocation')
export const ENTITY_SET_SAVING = id('entity_set_saving')
export const ENTITY_SET_SELECTION = id('entity_set_selection')
export const ENTITY_SET_TAB_CAPTION = id('entity_set_tab_caption')
export const ENTITY_SET_TAB_COUNT = id('entity_set_tab_count')
export const ENTITY_SET_TAGS = id('entity_set_tags')
export const ENTITY_SET_TYPE = id('entity_set_type')
export const ENTITY_SET_UNSAVED_NOTE = id('entity_set_unsaved_note')
export const ENTITY_SHOW_CONFETTI = id('entity_show_confetti')
export const ENTITY_UNMARK = id('entity_unmark')
export const ENTITY_UPDATE_CERTIFICATE = id('entity_update_certificate')
export const ENTITY_UPDATE_NOTE = id('entity_update_note')
export const ENTITY_UPDATE_NOTE_TYPE = id('entity_update_note_type')
export const ENTITY_UPDATE_DATA = id('entity_update_data')
export const ENTITY_UPLOAD_NOTE_ATTACHMENT = id('entity_upload_note_attachment')
export const ENTITY_UPLOAD_NOTE_ATTACHMENT_END = id(
  'entity_upload_note_attachment_end'
)
export const ENTITY_UPLOAD_NOTE_ATTACHMENT_START = id(
  'entity_upload_note_attachment_start'
)
export const ENTITY_UPLOAD_NOTE_ATTACHMENT_SUCCEED = id(
  'entity_upload_note_attachment_succeed'
)

// event
export const EVENT_CALENDAR_CHANGE_VIEW = id('event_calendar_change_view')
export const EVENT_CALENDAR_SET_DATE = id('event_calendar_set_date')
export const EVENT_CLEAR_NEW_ITEM = id('event_clear_new_item')
export const EVENT_CREATE = id('event_create')
export const EVENT_CREATE_SUCCEED = id('event_create_succeed')
export const EVENT_DOWNLOAD_ICS = id('event_download_ics')
export const EVENT_GET_ITEM = id('event_get_item')
export const EVENT_GET_ITEM_RANGE = id('event_get_item_range')
export const EVENT_GET_ITEM_RANGE_SUCCEED = id('event_get_item_range_succeed')
export const EVENT_GET_ITEM_SUCCEED = id('event_get_item_succeed')
export const EVENT_GET_LOCATIONS = id('event_get_locations')
export const EVENT_GET_LOCATIONS_SUCCEED = id('event_get_locations_succeed')
export const EVENT_GET_RANGE = id('event_get_range')
export const EVENT_GET_RANGE_SUCCEED = id('event_get_range_succeed')
export const EVENT_GET_SHARED_AGENDAS = id('event_get_shared_agendas')
export const EVENT_GET_SHARED_AGENDAS_SUCCEED = id(
  'event_get_shared_agendas_succeed'
)
export const EVENT_RANGE_ADD = id('event_range_add')
export const EVENT_REMOVE = id('event_remove')
export const EVENT_REMOVE_RECURRENCY = id('event_remove_recurrency')
export const EVENT_REMOVE_SUCCEED = id('event_remove_succeed')
export const EVENT_SAVE_NEW = id('event_save_new')
export const EVENT_SAVE_FINISHED = id('event_save_finished')
export const EVENT_SET_FILTER = id('event_set_filter')
export const EVENT_SET_FORM_OPEN = id('event_set_form_open')
export const EVENT_SET_ITEM = id('event_set_item')
export const EVENT_SET_NEW_ITEM = id('event_set_new_item')
export const EVENT_SET_SHARED_USERS = id('event_set_shared_users')
export const EVENT_SHARED_AGENDAS_CHANGED = id('event_shared_agendas_changed')
export const EVENT_TOGGLE_SHOW_USERS = id('event_toggle_show_users')
export const EVENT_UPDATE = id('event_update')
export const EVENT_UPDATE_ITEM = id('event_update_item')
export const EVENT_UPDATE_SUCCEED = id('event_update_succeed')

// exports
export const EXPORT_ADD_EXPORTS = id('export_add_exports')
export const EXPORT_LOAD_EXPORTS = id('export_load_exports')
export const EXPORT_SET_LOADING = id('export_set_loading')

// form
export const FORM_UPDATE_FIELD = '@@redux-form/CHANGE'

// funnel
export const FUNNEL_ADD_CUSTOMER = id('funnel_add_customer')
export const FUNNEL_GET_FUNNEL = id('funnel_get_funnel')
export const FUNNEL_LOAD_CUSTOMERS = id('funnel_load_customers')
export const FUNNEL_MOVE_CUSTOMER = id('funnel_move_customer')
export const FUNNEL_REMOVE_CUSTOMER = id('funnel_remove_customer')
export const FUNNEL_REMOVE_CUSTOMER_SUCCEED = id(
  'funnel_remove_customer_succeed'
)
export const FUNNEL_SET_ASSIGNED_TO = id('funnel_set_assigned_to')
export const FUNNEL_SHOW_CONFETTI = id('funnel_show_confetti')
export const FUNNEL_TIMELINE_LOAD = id('funnel_timeline_load')
export const FUNNEL_TIMELINE_LOAD_SUCCEED = id('funnel_timeline_load_succeed')

// iframe
export const IFRAME_RELOAD_FRAME = id('iframe_reload_frame')
export const IFRAME_SET_LOADING = id('iframe_set_loading')
export const IFRAME_SET_VISIBLE = id('iframe_set_visible')

// job
export const JOB_ADD_CANDIDATE = id('job_add_candidate')
export const JOB_ADD_LANGUAGE = id('job_add_language')
export const JOB_ADD_SOURCES = id('job_add_sources')
export const JOB_ASK_REVIEW = id('job_ask_review')
export const JOB_ASK_SEND_EMAIL = id('job_ask_send_email')
export const JOB_ASSIGN_PLACEMENT_TO = id('job_assign_placement_to')
export const JOB_CHANGE_BRANCH = id('job_change_branch')
export const JOB_CHECK_PHASES = id('job_check_phases')
export const JOB_CLEAR_PLACEMENTS = id('job_clear_placements')
export const JOB_COPY = id('job_copy')
export const JOB_DELETE_ALTERNATIVE = id('job_delete_alternative')
export const JOB_DELETE_ALTERNATIVE_SUCCEED = id(
  'job_delete_alternative_succeed'
)
export const JOB_DELETE_LANGUAGE = id('job_delete_language')
export const JOB_DELETE_VACANCY = id('job_delete_vacancy')
export const JOB_DELETE_VACANCY_SUCCEED = id('job_delete_vacancy_succeed')
export const JOB_DISABLE_SELECT_MODE = id('job_disable_select_mode')
export const JOB_EDIT_LANGUAGE = id('job_edit_language')
export const JOB_GET_CANDIDATE_VIEWERS = id('job_get_candidate_viewers')
export const JOB_GET_LANGUAGE = id('job_get_language')
export const JOB_GET_LANGUAGE_SUCCEED = id('job_get_language_succeed')
export const JOB_GET_PLACEMENTS = id('job_get_placements')
export const JOB_GET_REVIEW_USERS = id('job_get_review_users')
export const JOB_GET_REVIEW_USERS_SUCCEED = id('job_get_review_users_succeed')
export const JOB_LOAD_CANDIDATES = id('job_load_candidates')
export const JOB_LOAD_SOURCES = id('job_load_sources')
export const JOB_MOVE_CANDIDATE = id('job_move_candidate')
export const JOB_MOVE_CANDIDATES = id('job_move_candidates')
export const JOB_NETIVE_CONFIRM_CANCEL = id('job_netive_confirm_cancel')
export const JOB_NETIVE_CONFIRM_YES = id('job_netive_confirm_yes')
export const JOB_NETIVE_GET = id('job_netive_get')
export const JOB_NETIVE_GET_JSON = id('job_netive_get_json')
export const JOB_NETIVE_SET = id('job_netive_set')
export const JOB_NETIVE_SET_JSON = id('job_netive_set_json')
export const JOB_NETIVE_SET_PROPOSAL = id('job_netive_set_proposal')
export const JOB_NETIVE_UPDATE_PROPOSAL = id('job_netive_update_proposal')
export const JOB_PDF_ZOOMIN = id('job_pdf_zoomin')
export const JOB_PDF_ZOOMOUT = id('job_pdf_zoomout')
export const JOB_REMOVE_CANDIDATE = id('job_remove_candidate')
export const JOB_REMOVE_CANDIDATE_INIT = id('job_remove_candidate_init')
export const JOB_REMOVE_IMAGE = id('job_remove_image')
export const JOB_SELECT_CANDIDATES = id('job_select_candidates')
export const JOB_SELECT_PHASE = id('job_select_phase')
export const JOB_SELECT_PHASE_CANDIDATE = id('job_select_phase_candidate')
export const JOB_SELECTBAR_SET_CANDIDATEID = id('job_selectbar_set_candidateid')
export const JOB_SELECTBAR_SET_MATCHING = id('job_selectbar_set_matching')
export const JOB_SET_BARBUTTON = id('job_set_barbutton')
export const JOB_SET_BOARDFILTER = id('job_set_boardfilter')
export const JOB_SET_BOARDFILTER_ORIGIN = id('job_set_boardfilter_origin')
export const JOB_SET_BOARDFILTER_SELECT = id('job_set_boardfilter_select')
export const JOB_SET_PLACEHOLDERINDEX = id('job_set_placeholderindex')
export const JOB_SET_PLACEMENT_ONHOLD = id('job_set_placement_onhold')
export const JOB_SET_PLACEMENT_ORIGIN = id('job_set_placement_origin')
export const JOB_SET_PLACEMENT_STATUS = id('job_set_placement_status')
export const JOB_SET_SCROLL_POSITION = id('job_set_scroll_position')
export const JOB_SET_SOURCES_DIRTY = id('job_set_sources_dirty')
export const JOB_SET_UPLOADING = id('job_set_uploading')
export const JOB_TIMELINE_LOAD = id('job_timeline_load')
export const JOB_TIMELINE_LOAD_SUCCEED = id('job_timeline_load_succeed')
export const JOB_UPDATE_IMAGE = id('job_update_image')
export const JOB_UPDATE_LANGUAGE = id('job_update_language')
export const JOB_UPDATE_PUBLISH = id('job_update_publish')
export const JOB_UPLOAD_ALTERNATIVE = id('job_upload_alternative')
export const JOB_UPLOAD_DESCRIPTION = id('job_upload_description')
export const JOB_UPLOAD_VACANCY = id('job_upload_vacancy')

// lookup
export const LOOKUP_ADD_ITEM = id('lookup_add_item')
export const LOOKUP_ADD_ITEM_SUCCEED = id('lookup_add_item_succeed')
export const LOOKUP_CLEAR_LOOKUP = id('lookup_clear_lookup')
export const LOOKUP_DELETE_ITEM = id('lookup_delete_item')
export const LOOKUP_DELETE_ITEM_SUCCEED = id('lookup_delete_item_succeed')
export const LOOKUP_GET_LOOKUP = id('lookup_get_lookup')
export const LOOKUP_IMPORT_EXCEL = id('lookup_import_excel')
export const LOOKUP_IMPORT_EXCEL_UPLOADING = id('lookup_import_excel_uploading')
export const LOOKUP_LOAD_COUNTRIES = id('lookup_load_countries')
export const LOOKUP_LOAD_LANGUAGES = id('lookup_load_languages')
export const LOOKUP_MOVE_ITEM = id('lookup_move_item')
export const LOOKUP_MOVE_ITEM_INTERN = id('lookup_move_item_intern')
export const LOOKUP_RESTORE_ALL = id('lookup_restore_all')
export const LOOKUP_RESTORE_ITEM = id('lookup_restore_item')
export const LOOKUP_RESTORE_ITEM_SUCCEED = id('lookup_restore_item_succeed')
export const LOOKUP_SET_COUNTRIES = id('lookup_set_countries')
export const LOOKUP_SET_LANGUAGE = id('lookup_set_language')
export const LOOKUP_SET_LANGUAGES = id('lookup_set_languages')
export const LOOKUP_SET_LOOKUP = id('lookup_set_lookup')
export const LOOKUP_SORT_LIST = id('lookup_sort_list')
export const LOOKUP_UPDATE_ITEM = id('lookup_update_item')
export const LOOKUP_UPDATE_ITEM_SUCCEED = id('lookup_update_item_succeed')

// notification
export const NOTIFICATION_ADD = id('NOTIFICATION_ADD')
export const NOTIFICATION_GET_NEW = id('notification_get_new')
export const NOTIFICATION_GET_NEW_SUCCEED = id('notification_get_new_succeed')
export const NOTIFICATION_GET_SUBSCRIPTIONS = id(
  'notification_get_subscriptions'
)
export const NOTIFICATION_GET_SUBSCRIPTIONS_SUCCEED = id(
  'notification_get_subscriptions_succeed'
)
export const NOTIFICATION_LOAD_ALL = id('notification_load_all')
export const NOTIFICATION_LOAD_ALL_SUCCEED = id('notification_load_all_succeed')
export const NOTIFICATION_REMOVE_READ = id('notification_remove_read')
export const NOTIFICATION_RECEIVE_MESSAGE = id('notification_receive_message')
export const NOTIFICATION_SET_LOADING = id('notification_set_loading')
export const NOTIFICATION_SET_READ = id('notification_set_read')
export const NOTIFICATION_SET_READ_ALL = id('notification_set_read_all')
export const NOTIFICATION_UPDATE_SUBSCRIPTION = id(
  'notification_update_subscription'
)

// pdf
export const PDF_CREATE = id('pdf_create')
export const PDF_CREATING = id('pdf_creating')
export const PDF_GENERATE = id('pdf_generate')
export const PDF_GENERATE_SUCCEED = id('pdf_generate_succeed')

// project
export const PROJECT_ADD_SOURCES = id('project_add_sources')
export const PROJECT_ADD_TIMELINE = id('project_add_timeline')
export const PROJECT_CHECK_TIMELINE = id('project_check_timeline')
export const PROJECT_DELETE_TIMELINE = id('project_delete_timeline')
export const PROJECT_EDIT_TIMELINE = id('project_edit_timeline')
export const PROJECT_GET_CANDIDATES = id('project_get_candidates')
export const PROJECT_GET_CANDIDATES_SUCCEED = id(
  'project_get_candidates_succeed'
)
export const PROJECT_GET_PLANNING = id('project_get_planning')
export const PROJECT_GET_PLANNING_SUCCEED = id('project_get_planning_succeed')
export const PROJECT_GET_TIMELINE = id('project_get_timeline')
export const PROJECT_GET_TIMELINES = id('project_get_timelines')
export const PROJECT_GET_TIMELINES_SUCCEED = id('project_get_timelines_succeed')
export const PROJECT_GOTO_PROJECT = id('project_goto_project')
export const PROJECT_JOB_MOVE_DOWN = id('project_job_move_down')
export const PROJECT_JOB_MOVE_UP = id('project_job_move_up')
export const PROJECT_LOAD_SOURCES = id('project_load_sources')
export const PROJECT_REMOVE_JOB = id('project_remove_job')
export const PROJECT_RESET_TIMELINE_INTERVAL = id(
  'project_reset_timeline_interval'
)
export const PROJECT_SET_INTERVAL = id('project_set_interval')
export const PROJECT_SET_LOADING = id('project_set_loading')
export const PROJECT_SET_SCROLL_POSITION = id('project_set_scroll_position')
export const PROJECT_SET_SOURCES_DIRTY = id('project_set_sources_dirty')
export const PROJECT_SET_TIMELINE_INTERVAL = id('project_set_timeline_interval')

// relation
export const RELATION_ADD_SHORTLIST = id('relation_add_shortlist')
export const RELATION_ADD_SOURCES = id('relation_add_sources')
export const RELATION_DELETE_PHOTO = id('relation_delete_photo')
export const RELATION_DELETE_PHOTO_SUCCEED = id('relation_delete_photo_succeed')
export const RELATION_GET_CANDIDATES = id('relation_get_candidates')
export const RELATION_GET_CANDIDATES_INIT = id('relation_get_candidates_init')
export const RELATION_GET_SHORTLIST = id('relation_get_shortlist')
export const RELATION_GET_SHORTLIST_INIT = id('relation_get_shortlist_init')
export const RELATION_GET_JOBS = id('relation_get_job')
export const RELATION_GET_JOBS_INIT = id('relation_get_jobs_init')
export const RELATION_GET_PRIMARY_CONTACT = id('relation_get_primary_contact')
export const RELATION_GOTO_JOBS = id('relation_goto_jobs')
export const RELATION_LOAD_SOURCES = id('relation_load_sources')
export const RELATION_REMOVE_SHORTLIST = id('relation_remove_shortlist')
export const RELATION_SEARCH_CLAIMS = id('relation_search_claims')
export const RELATION_SEARCH_CLAIMS_SUCCEED = id(
  'relation_search_claims_succeed'
)
export const RELATION_SEARCH_COC = id('relation_search_coc')
export const RELATION_SEARCH_COC_SUCCEED = id('relation_search_coc_succeed')
export const RELATION_SELECT_CONTACTS = id('relation_select_contacts')
export const RELATION_SET_CANDIDATES_FILTER = id(
  'relation_set_candidates_filter'
)
export const RELATION_SET_CANDIDATES_LOADED = id(
  'relation_set_candidates_loaded'
)
export const RELATION_SET_JOBS_FILTER = id('relation_set_jobs_filter')
export const RELATION_SET_JOBS_LOADED = id('relation_set_jobs_loaded')
export const RELATION_SET_SOURCES_DIRTY = id('relation_set_sources_dirty')
export const RELATION_SHORTLIST_CLEAR_SELECT = id(
  'relation_shortlist_clear_select'
)
export const RELATION_SHORTLIST_DESELECT = id('relation_shortlist_deselect')
export const RELATION_SHORTLIST_INVERSE_SELECTION = id(
  'relation_shortlist_inverse_selection'
)
export const RELATION_SHORTLIST_SELECT = id('relation_shortlist_select')
export const RELATION_UPDATE_CLAIMED = id('relation_update_claimed')
export const RELATION_UPLOAD_PHOTO = id('relation_upload_photo')
export const RELATION_UPLOAD_PHOTO_SUCCEED = id('relation_upload_photo_succeed')

// quick
export const QUICK_GET_ITEM = id('quick_get_item')
export const QUICK_GET_ITEM_SUCCEED = id('quick_get_item_succeed')
export const QUICK_GET_LIST = id('quick_get_list')
export const QUICK_GET_LIST_SUCCEED = id('quick_get_list_succeed')
export const QUICK_LOADING = id('quick_loading')
export const QUICK_LOADING_ITEM = id('quick_loading_item')
export const QUICK_PIN_ITEM = id('quick_pin_item')
export const QUICK_SET_HIGHLIGHT = id('quick_set_highlight')
export const QUICK_SET_SELECTED = id('quick_set_selected')
export const QUICK_SET_TAB = id('quick_set_tab')
export const QUICK_UNPIN_ITEM = id('quick_unpin_item')

// share
export const SHARE_ACCEPT_CANDIDATE = id('share_accept_candidate')
export const SHARE_GET_CANDIDATE = id('share_get_candidate')
export const SHARE_GET_CANDIDATE_SUCCEED = id('share_get_candidate_succeed')
export const SHARE_GET_CANDIDATES = id('share_get_candidates')
export const SHARE_GET_CANDIDATES_SUCCEED = id('share_get_candidates_succeed')
export const SHARE_GET_NOTES = id('share_get_notes')
export const SHARE_GET_NOTES_SUCCEED = id('share_get_notes_succeed')
export const SHARE_GET_PHASES = id('share_get_phases')
export const SHARE_GET_PHASES_SUCCEED = id('share_get_phases_succeed')
export const SHARE_GET_REJECT_REASONS = id('share_get_reject_reasons')
export const SHARE_GET_REJECT_REASONS_SUCCEED = id(
  'share_get_reject_reasons_succeed'
)
export const SHARE_REJECT_CANDIDATE = id('share_reject_candidate')
export const SHARE_SET_NOT_FOUND = id('share_set_not_found')
export const SHARE_SET_ID = id('share_set_id')
export const SHARE_SET_SHARE = id('share_set_share')
export const SHARE_UPDATE_CANDIDATE = id('share_update_candidate')
export const SHARE_UPDATE_EVALUATION = id('share_update_evaluation')
export const SHARE_UPDATE_NOTE = id('share_update_note')
export const SHARE_UPDATE_VIEWER = id('share_update_viewer')

// search
export const SEARCH_ADD_TO_LIST = id('search_add_to_list')
export const SEARCH_ADD_TO_SELECTED = id('search_add_to_selected')
export const SEARCH_SEARCH_TEXT = id('search_search_text')
export const SEARCH_SEARCH_TEXT_SUCCEED = id('search_search_text_succeed')
export const SEARCH_SET_LIST = id('search_set_list')
export const SEARCH_SET_NO_RESULT = id('search_set_no_result')
export const SEARCH_SET_SELECTED = id('seatch_set_selected')

// shell
export const SHELL_CONFIRM = id('shell_confirm')
export const SHELL_CONFIRM_CANCEL = id('shell_confirm_cancel')
export const SHELL_CONFIRM_NO = id('shell_confirm_no')
export const SHELL_CONFIRM_YES = id('shell_confirm_yes')
export const SHELL_MESSAGE = id('shell_message')
export const SHELL_MESSAGE_SHOW = id('shell_message_show')
export const SHELL_MESSAGE_HIDE = id('shell_message_hide')

// table
export const TABLE_ADD_FILTER_ITEM = id('table_add_filter_item')
export const TABLE_ADD_TALENTPOOL_FILTER = id('table_add_talentpool_filter')
export const TABLE_ADD_TAG_FILTER = id('table_add_tag_filter')
export const TABLE_ADD_TAGS = id('table_add_tags')
export const TABLE_CLEAR_FILTERS = id('table_clear_filters')
export const TABLE_COPY = id('table_copy')
export const TABLE_DELETE_CURRENT_FILTER = id('table_delete_current_filter')
export const TABLE_DELETE_FILTER = id('table_delete_filter')
export const TABLE_DELETE_FILTERS = id('table_delete_filters')
export const TABLE_DELETE_ITEMS = id('table_delete_items')
export const TABLE_DESELECT_ITEM = id('table_deselect_item')
export const TABLE_EXPORT_SELECTION = id('table_export_selection')
export const TABLE_EXPORT_SHOW_DIALOG = id('table_export_show_dialog')
export const TABLE_GET_INSIGHTS_URL = id('table_get_insights_url')
export const TABLE_GET_LAST_NOTE = id('table_get_last_note')
export const TABLE_GET_MATCHING = id('table_get_matching')
export const TABLE_GET_MATCHING_SUCCEED = id('table_get_matching_succeed')
export const TABLE_INSERT_ITEM = id('table_insert_item')
export const TABLE_LOAD = id('table_load')
export const TABLE_LOAD_INIT = id('table_load_init')
export const TABLE_RELOAD = id('table_reload')
export const TABLE_REMOVE_FILTER_ITEM = id('table_remove_filter_item')
export const TABLE_REMOVE_ITEM = id('table_remove_item')
export const TABLE_REMOVE_ITEMS = id('table_remove_items')
export const TABLE_RESET = id('table_reset')
export const TABLE_RESET_COPY = id('table_reset_copy')
export const TABLE_RESET_SEARCH = id('table_reset_search')
export const TABLE_RESTORE_COPY = id('table_restore_copy')
export const TABLE_SAVE_CURRENT_FILTER = id('table_save_current_filter')
export const TABLE_SAVE_MATCHING = id('table_save_matching')
export const TABLE_SELECT_ALL = id('table_select_all')
export const TABLE_SELECT_FILTER_ITEM = id('table_select_filter_item')
export const TABLE_SELECT_ITEM = id('table_select_item')
export const TABLE_SET_DASHBOARD_FILTER = id('table_set_dashboard_filter')
export const TABLE_SET_FILTER = id('table_set_filter')
export const TABLE_SET_FILTER_LIST = id('table_set_filter_list')
export const TABLE_SET_FILTER_SEARCH = id('table_set_filter_search')
export const TABLE_SET_INITDATA = id('table_set_initdata')
export const TABLE_SET_INSIGHTS = id('table_set_insights')
export const TABLE_SET_LOADING = id('table_set_loading')
export const TABLE_SET_MATCH_INFO = id('table_set_match_info')
export const TABLE_SET_MATCHING_LOADED = id('table_set_matching_loaded')
export const TABLE_SET_ORDER = id('table_set_order')
export const TABLE_SET_PREVLOCATION = id('table_set_prevlocation')
export const TABLE_SET_SCROLLTOP = id('table_set_scrolltop')
export const TABLE_SET_SAVED_FILTER = id('table_set_saved_filter')
export const TABLE_SET_SAVED_FILTER_FIELDS = id('table_set_saved_filter_fields')
export const TABLE_SET_SAVED_FILTER_NONE = id('table_set_saved_filter_none')
export const TABLE_SET_SEARCH_TEXT = id('table_set_search_text')
export const TABLE_SET_SELECTED = id('table_set_selected')
export const TABLE_SET_SEARCH_FIELD = id('table_set_search_field')
export const TABLE_SET_SKIP = id('table_set_skip')
export const TABLE_SET_TAB = id('table_set_tab')
export const TABLE_SET_TYPE = id('table_set_type')
export const TABLE_SORT_SELECTED = id('table_sort_selected')
export const TABLE_TOGGLE_FILTER_OPERATOR = id('table_toggle_filter_operator')
export const TABLE_TOGGLE_FILTER_PANEL = id('table_toggle_filter_panel')
export const TABLE_TYPE_SET = id('table_type_set')

// task
export const TASK_ADD_TASKS = id('task_add_tasks')
export const TASK_ADD_MY_TASKS = id('task_add_my_tasks')
export const TASK_COMPLETED = id('task_completed')
export const TASK_CREATE = id('task_create')
export const TASK_GET_ITEM = id('task_get_item')
export const TASK_GET_ITEM_SUCCEED = id('task_get_item_succeed')
export const TASK_GET_SHARED_TASKS = id('task_get_shared_tasks')
export const TASK_GET_SHARED_TASKS_SUCCEED = id('task_get_shared_tasks_succeed')
export const TASK_LOAD_TASKS = id('task_load_tasks')
export const TASK_LOAD_MY_TASKS = id('task_load_my_tasks')
export const TASK_SET_FILTER = id('task_set_filter')
export const TASK_SET_FORM_OPEN = id('task_set_form_open')
export const TASK_SET_LOADING = id('task_set_loading')
export const TASK_SET_SEARCH_FILTER = id('task_set_search_filter')
export const TASK_SET_SHARED_USERS = id('task_set_shared_users')
export const TASK_SET_TAB = id('task_set_tab')
export const TASK_SET_TAB_VALUE = id('task_set_tab_value')
export const TASK_SHARED_TASKS_CHANGED = id('task_shared_tasks_changed')
export const TASK_UPDATE = id('task_update')

// template
export const TEMPLATE_ACTIVATE = id('template_activate')
export const TEMPLATE_ADD_ATTACHMENT = id('template_add_attachment')
export const TEMPLATE_ADD_ATTACHMENT_SUCCEED = id(
  'template_add_attachment_succeed'
)
export const TEMPLATE_COPY = id('template_copy')
export const TEMPLATE_CREATE = id('template_create')
export const TEMPLATE_DEACTIVATE = id('template_deactivate')
export const TEMPLATE_GET = id('template_get')
export const TEMPLATE_GET_NEW = id('template_get_new')
export const TEMPLATE_GET_SUCCEED = id('template_get_succeed')
export const TEMPLATE_MEDIA_CREATE = id('template_media_create')
export const TEMPLATE_MEDIA_GET = id('template_media_get')
export const TEMPLATE_MEDIA_GET_SUCCEED = id('template_media_get_succeed')
export const TEMPLATE_MEDIA_LOAD = id('template_media_load')
export const TEMPLATE_MEDIA_LOAD_SUCCEED = id('template_media_load_succeed')
export const TEMPLATE_MEDIA_REMOVE = id('template_media_remove')
export const TEMPLATE_MEDIA_UPDATE = id('template_media_update')
export const TEMPLATE_RESET_DATA = id('template_reset_data')
export const TEMPLATE_SET_DATA = id('template_set_data')
export const TEMPLATE_SET_FILTER = id('template_set_filter')
export const TEMPLATE_SET_UPLOADING = id('template_set_uploading')
export const TEMPLATE_UPDATE = id('template_update')

// theme
export const THEME_SET_THEME = id('theme_set_theme')
export const THEME_SET_THEME_COLORS = id('theme_set_theme_colors')

// user
export const USER_ACTIVATE = id('user_activate')
export const USER_CREATE = id('user_create')
export const USER_CREATE_VIEWER = id('user_create_viewer')
export const USER_DEACTIVATE = id('user_deactivate')
export const USER_DELETE_MY_PHOTO = id('user_delete_my_photo')
export const USER_DELETE_MY_PHOTO_SUCCEED = id('user_delete_my_photo_succeed')
export const USER_EDIT = id('user_edit')
export const USER_GET_MY = id('user_get_my')
export const USER_INVITE = id('user_invite')
export const USER_LOG_LOCATION = id('user_log_location')
export const USER_PUT_MY = id('user_put_my')
export const USER_PUT_MY_THEME = id('user_put_my_theme')
export const USER_REMOVE_VIEWER = id('user_remove_viewer')
export const USER_SAVE_DEFAULT_TEMPLATE = id('user_save_default_template')
export const USER_SAVE_FILTER = id('user_save_filter')
export const USER_SIGNOUT = id('user_signout')
export const USER_SET_MY_DATA = id('user_set_my_data')
export const USER_SET_SAVING = id('user_set_saving')
export const USER_TERMINATE = id('user_terminate')
export const USER_UPDATE_VIEWER = id('user_update_viewer')
export const USER_UPLOAD_MY_PHOTO = id('user_upload_my_photo')
export const USER_UPLOAD_MY_PHOTO_SUCCEED = id('user_upload_my_photo_succeed')
